import React from "react";
import { formatDateToReadableString } from "../../../../utils/date-utils";
import { formatFileSize } from "../../../../utils/file-utils";
import EditableTextCard from "../../../common/editable-text-card";

const FileInformationTab = ({ file }) => {
  return (
    <>
      <div className="tab-content">
        <EditableTextCard
          title="File Name"
          initialText={file.name}
          editable={false}
        />
        <EditableTextCard title="Type" initialText={"Image"} editable={false} />
        <EditableTextCard
          title="Size"
          initialText={formatFileSize(file.size)}
          editable={false}
        />
        <EditableTextCard
          title="Created"
          initialText={formatDateToReadableString(file.created_at)}
          editable={false}
        />
        <EditableTextCard
          title="Modified"
          initialText={formatDateToReadableString(file.created_at)}
          editable={false}
        />
      </div>
    </>
  );
};

export default FileInformationTab;
