import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({
  isOpen,
  toggle,
  title,
  description,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  onConfirm,
  isDanger = false,
}) => {
  const handleDoubleClick = (event) => {
    // Preventing event bubbling which was causing any double clicks on this modal trigger double click of parent folder/file item
    event.stopPropagation();
  };

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={toggle}
      className="custom-modal"
      onDoubleClick={handleDoubleClick}
    >
      <ModalHeader toggle={toggle} className={"border-bottom-0"}>
        {title}
      </ModalHeader>
      <ModalBody>{description}</ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={toggle}
        >
          {cancelButtonText}
        </Button>
        <Button color={isDanger ? "danger" : "primary"} onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
