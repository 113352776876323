import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import noPhoto from "../../../../assets/images/no-photo.svg";
import ConfirmationModal from "../../components/common/confirmation-modal";
import { useDispatch, useSelector } from "react-redux";
import { JoinString } from "../../../proxima-admin/common/join-string-utiliy";
import { resetUser } from "../../../../redux/slices/user-slice";
import { updateUser } from "../../redux/slices/user-slice";

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [menuOpen, setMenuOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const toggleMenu = () => setMenuOpen((prevState) => !prevState);
  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleLogout =async () => {
    // ToDo: Handle logout api here
    localStorage.clear();
    window.location.reload()
    //navigate("/auth/login")
    toggleConfirmationModal();
  };

  return (
    <>
      <Dropdown isOpen={menuOpen} toggle={toggleMenu} className="context-menu">
        <DropdownToggle className="bg-transparent text-dark border-0 p-0">
          <div
            className="d-inline-flex align-items-center"
            style={{ verticalAlign: "middle" }}
          >
            <img style={{ width: 32, borderRadius: "50%" }} src={noPhoto} />
          </div>
        </DropdownToggle>
        <DropdownMenu className=" shadow bg-light" right>
          <DropdownItem>
            <div className="d-flex align-items-center gap-3 ">
              <div className="d-flex align-items-center">
                <img style={{ width: 35, borderRadius: "50%" }} src={noPhoto} />
              </div>

              <div className="d-flex flex-column" style={{ lineHeight: 1.5 }}>
                <p className="mb-0 font-weight-bold">
                  {JoinString(user.first_name, user.last_name)}
                </p>
                <p className="mb-0 color-light small">{user.email}</p>
              </div>
            </div>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() =>
              navigate("/app/profile", {
                state: { tab: 1 },
              })
            }
          >
            <i className={"material-icons-outlined"}>person</i>
            View Profile
          </DropdownItem>
          <DropdownItem
            onClick={() =>
              navigate("/app/profile", {
                state: { tab: 2 },
              })
            }
          >
            <i className={"material-icons-outlined"}>settings</i>
            Account Settings
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={toggleConfirmationModal}>
            <i className={"material-icons-outlined"}>power_settings_new</i>
            Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ConfirmationModal
        isOpen={confirmationModal}
        toggle={toggleConfirmationModal}
        title="Logout?"
        description="Do you really want to log out? You will need to log in again to access your account."
        confirmButtonText="Logout"
        onConfirm={handleLogout}
        isDanger={true}
      />
    </>
  );
};

export default ProfileDropdown;
