import React, { useState } from "react";
import ImageViewerModal from "../image-viewer/image-viewer-modal";
import FileThumbnailItem from "./cards/file-thumbnail-item";
import SectionHeader from "./header/section-header";

const FileThumbnails = ({ files }) => {
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [imageViewerModal, setImageViewerModal] = useState(false);

  // Single click handler
  const handleClick = (file) => {};

  // Double click handler
  const handleDoubleClick = (index) => {
    setImageViewerModal(true);
    setSelectedFileIndex(index);
  };

  return files && files.length > 0 ? (
    <>
      <div className="file-thumbnails">
        <SectionHeader title="Files" />
        <div className="grid">
          {files?.map((file, index) => (
            <FileThumbnailItem
              file={file}
              key={file._id}
              onClick={() => handleClick(file)}
              onDoubleClick={() => handleDoubleClick(index)}
            />
          ))}
        </div>
      </div>
      {imageViewerModal ? (
        <ImageViewerModal
          isOpen={imageViewerModal}
          toggle={() => setImageViewerModal(!imageViewerModal)}
          files={files}
          currentFileIndex={selectedFileIndex}
          isTrash={files[selectedFileIndex]?.is_bin}
        />
      ) : null}
    </>
  ) : (
    <></>
  );
};

export default FileThumbnails;
