import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { getBreadcrumbs } from "../../redux/slices/active-folder-slice";
import routes from "../../layout/routes/routes";

const StorageBreadcrumb = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const folders = useSelector(getBreadcrumbs);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");

  const handleFolderClick = (folderId) => {
    if (!folderId || folderId == "") {
      navigate(storageRoute.layout + storageRoute.path);
    } else {
      navigate(
        `${storageRoute.layout + storageRoute.path}?folderId=${folderId}`
      );
    }
  };

  const handleRootNavigation = (link) => {
    navigate(link);
  };

  const renderBreadcrumbs = () => {
    const totalFolders = folders.length;

    if (totalFolders <= 3) {
      return folders.map((folder, index) => (
        <BreadcrumbItem key={folder._id} active={index === totalFolders - 1}>
          {index === totalFolders - 1 ? (
            <div className="breadcrumb-link-active">{folder.folder_name}</div>
          ) : (
            <div
              className="breadcrumb-link"
              onClick={() =>
                index == 0
                  ? handleRootNavigation(folders[0].rootLink)
                  : handleFolderClick(folder._id)
              }
            >
              {folder.folder_name}
            </div>
          )}
        </BreadcrumbItem>
      ));
    } else {
      return (
        <>
          <BreadcrumbItem>
            <div
              className="breadcrumb-link"
              onClick={() => handleRootNavigation(folders[0].rootLink)}
            >
              {folders[0].folder_name}
            </div>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>
                <i className="material-icons">more_horiz</i>
              </DropdownToggle>
              <DropdownMenu>
                {folders.slice(1, totalFolders - 2).map((folder) => (
                  <DropdownItem
                    key={folder._id}
                    onClick={() => handleFolderClick(folder._id)}
                  >
                    {folder.folder_name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <div
              className="breadcrumb-link"
              onClick={() =>
                handleFolderClick(folders[totalFolders - 2]._id)
              }
            >
              {folders[totalFolders - 2].folder_name}
            </div>
          </BreadcrumbItem>
          <BreadcrumbItem active>
            <div className="breadcrumb-link-active">
              {folders[totalFolders - 1].folder_name}
            </div>
          </BreadcrumbItem>
        </>
      );
    }
  };

  return (
    <div className="flex-grow-1">
      <Breadcrumb>{renderBreadcrumbs()}</Breadcrumb>
    </div>
  );
};

export default StorageBreadcrumb;
