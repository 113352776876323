import { format, formatDistanceToNow } from "date-fns";

export function getRelativeTime(dateString) {
  const date = new Date(dateString);
  return formatDistanceToNow(date, { addSuffix: true });
}

export function formatDateToReadableString(date) {
  return format(new Date(date), "MMM dd, yyyy, h:mm a");
}
