// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { updateOrganization } from './organization-slice'; // Import the action to update the organization
// import { loginUser } from '../services/auth-service';

const initialState = {
};



const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {   updateUser: (state, action) => {
    return {
      ...state,
      ...action.payload
    };
  },},
 
});

export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
