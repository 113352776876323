import React from "react";
import { CustomPicker } from "react-color";
import { Saturation, Hue } from "react-color/lib/components/common";

const presetColors = [
  "#e74c3c",
  "#3498db",
  "#2ecc71",
  "#f1c40f",
  "#9b59b6",
  "#1abc9c",
  "#e67e22",
  "#34495e",
  "#7f8c8d",
  "#ffffff",
];

const ColorPalette = ({ hex, hsl, hsv, onChange }) => {
  const handleChange = (color) => {
    onChange(color);
  };

  const handleMouseDown = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="color-palette" onMouseDown={handleMouseDown}>
      <div style={{ width: "200px", height: "150px", position: "relative" }}>
        <Saturation hsl={hsl} hsv={hsv} onChange={handleChange} />
      </div>
      <div
        style={{
          width: "200px",
          height: "10px",
          position: "relative",
          marginTop: "10px",
        }}
      >
        <Hue hsl={hsl} onChange={handleChange} />
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
        {presetColors.map((color, i) => (
          <div
            key={i}
            style={{
              backgroundColor: color,
              width: "20px",
              height: "20px",
              margin: "2px",
              cursor: "pointer",
            }}
            onClick={() => onChange({ hex: color })}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomPicker(ColorPalette);
