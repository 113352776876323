import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormFeedback,
} from "reactstrap";
import { changeUserPassword } from "../../../../redux/slices/user-slice";

const UpdatePassword = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const { status, email: userEmail } = useSelector((state) => state.user);
  const [passwords, setPasswords] = useState({ old: "", new: "", confirm: "" });
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
    setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // disable submission if passwords don't match or new password is same as old
    if (passwords.new !== passwords.confirm) return;
    if (passwords.old === passwords.new) return;

    dispatch(
      changeUserPassword({
        credentials: {
          email: userEmail,
          oldPassword: passwords.old,
          newPassword: passwords.new,
        },
        successHandler: () => {
          // reset the form and close modal on success
          setPasswords({ old: "", new: "", confirm: "" });
          setErrorMessage("");
          toggle();
        },
        errorHandler: () => setErrorMessage("Current password incorrect"),
      })
    );
  };

  // check if new password and confirm password don't match
  const passwordsDoNotMatch =
    passwords.new !== passwords.confirm && passwords.confirm !== "";

  // check if new password is same as current password
  const newPasswordSameAsCurrent =
    passwords.old === passwords.new && passwords.new !== "";

  return (
    <Modal
      centered
      isOpen={modal}
      toggle={toggle}
      className="modal-md custom-modal"
    >
      <ModalHeader toggle={toggle}>Change Password</ModalHeader>
      <ModalBody className="py-4">
        <Form onSubmit={handleSubmit}>
          {["old", "new", "confirm"].map((type) => (
            <Row form key={type}>
              <Col md="10">
                <FormGroup>
                  <Label for={`${type}Password`}>
                    {type === "old"
                      ? "Current"
                      : type === "confirm"
                      ? "Re-enter New"
                      : "New"}{" "}
                    Password
                  </Label>
                  <Input
                    type="password"
                    name={type}
                    id={`${type}Password`}
                    value={passwords[type]}
                    onChange={handleChange}
                    invalid={
                      // invalid state for various conditions
                      (type === "confirm" && passwordsDoNotMatch) ||
                      (type === "old" && errorMessage) ||
                      (type === "new" && newPasswordSameAsCurrent)
                    }
                  />
                  {type === "confirm" && passwordsDoNotMatch && (
                    <FormFeedback>Passwords do not match</FormFeedback>
                  )}
                  {type === "old" && errorMessage && (
                    <FormFeedback>{errorMessage}</FormFeedback>
                  )}
                  {type === "new" && newPasswordSameAsCurrent && (
                    <FormFeedback>
                      New password cannot be same as current password
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          ))}
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              // disable button if loading or passwords don't match, or new password is same as current
              disabled={
                status === "loading" ||
                passwordsDoNotMatch ||
                newPasswordSameAsCurrent
              }
            >
              {status === "loading" ? "Updating..." : "Update Password"}
            </Button>
            <Button
              color="link"
              className="color-light font-weight-bold"
              onClick={toggle}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdatePassword;
