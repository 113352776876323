export default class SelectionManager {
  constructor() {
    this.selectedShape = null;
  }

  selectShape(shapes, x, y) {
    this.selectedShape = shapes.find(
      (shape) => shape.isPointInside && shape.isPointInside(x, y)
    );
    shapes.forEach((shape) => {
      if (shape.setSelected) {
        shape.setSelected(shape === this.selectedShape);
      }
    });
    return this.selectedShape;
  }

  getSelectedShape() {
    return this.selectedShape;
  }

  clearSelection() {
    if (this.selectedShape && this.selectedShape.setSelected) {
      this.selectedShape.setSelected(false);
    }
    this.selectedShape = null;
  }

  deleteSelectedShape(shapes) {
    if (this.selectedShape) {
      const newShapes = shapes.filter((shape) => shape !== this.selectedShape);
      this.clearSelection();
      return newShapes;
    }
    return shapes;
  }
}
