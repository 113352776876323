import React, { useState } from "react";
import { Badge, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

/**
 * TagsList Component
 *
 * @param {Array} tags - The list of tags to be displayed. Each tag is a string.
 * @param {Boolean} isEditing - Determines if the tags are editable. If true, tags can be removed.
 * @param {Function} handleRemove - Function to call when a tag is removed. Receives the removed tag as an argument.
 * @param {Number} [max] - Optional. Limits the number of tags displayed. If not provided, all tags are shown.
 * @param {Boolean} select - Determines whether the tags are selectable.
 * @param {Function} onClick - Function to call when a tag is clicked. Receives the clicked tag as an argument.
 */
const TagsList = ({ tags, isEditing, onRemove, max, select, onClick }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleMouseEnter = () => setDropdownOpen(true);
  const handleMouseLeave = () => setDropdownOpen(false);

  const remainingTagsCount = tags?.length - max;

  return (
    <>
      {tags?.length > 0 && (
        <div className="tags-badge-container">
          {tags.slice(0, max).map((tag, index) => (
            <Badge
              key={index}
              color="link"
              className={`tag-badge ${select ? "can-select" : "no-select"}`}
              onClick={() => {
                onClick && onClick(tag);
              }}
            >
              <div className="label">{tag}</div>
              {isEditing && (
                <i
                  className="material-icons icon-button"
                  onClick={() => {
                    onRemove(tag);
                  }}
                >
                  close
                </i>
              )}
            </Badge>
          ))}

          {tags.length > max && (
            <Dropdown
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="more-tags-dropdown"
            >
              <DropdownToggle
                tag="div"
                className="more-tags-toggle badge tag-badge no-select"
              >
                <div className="label">+ {remainingTagsCount}</div>
              </DropdownToggle>
              <DropdownMenu>
                <div className="dropdown-header no-select">More</div>
                <div className="dropdown-item-text">
                  {tags.slice(max).join(", ")}
                </div>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      )}
    </>
  );
};

export default TagsList;
