import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FilterDropdown from "./filter-dropdown";
import {
  fetchTagsAction,
  getTagSuggestions,
} from "../../../redux/slices/search-slice";
import routes from "../../../layout/routes/routes";

const TagsFilter = ({ orgId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tags = useSelector(getTagSuggestions);
  const queryParams = new URLSearchParams(location.search);
  const tagsParam = queryParams.get("tags");

  const searchRoute = routes.find((route) => route.id === "SEARCH");

  useEffect(() => {
    if(orgId){
      dispatch(fetchTagsAction(orgId));
    }
  
  }, [orgId]);

  const handleTagSelect = (selectedTags) => {
    queryParams.set("tags", selectedTags.join(",")); // Set selected tags as a comma-separated list
    // navigate(`${location.pathname}?${queryParams.toString()}`);
    navigate(
      `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
    );
  };

  const handleClearTags = () => {
    queryParams.delete("tags"); // Clear the tags filter from query parameters
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <FilterDropdown
        title="Tags"
        items={tags}
        isMultiSelect={true}
        showSearch={true}
        onItemSelect={handleTagSelect}
        onClearSelection={handleClearTags}
        initialSelectedItems={
          tagsParam
            ? tagsParam.split(",").filter((tag) => tags.includes(tag))
            : []
        }
      />
    </>
  );
};

export default TagsFilter;
