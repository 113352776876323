import { Col, Container, Row } from "reactstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { JoinString } from "../../common/join-string-utiliy";
import UpdateUserProfile from "./update-user-profile";
import NoPhotoSVG from "../../assets/images/no-photo.svg"
export const Personal = () => {
  const user = useSelector((state) => state.user);

  console.log(user);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  return (
    <Container fluid>
      <Row className="py-4">
        <h5 className="w-100">Personal Information</h5>
        <p>Basic information like your name and contact number</p>
      </Row>
      <Row>
        <Col className="border">
          <Row className="border-bottom py-3 align-items-center">
            <Col md="3">
              <p className="color-light mb-0">Full Name</p>
            </Col>
            <Col xs={10} md="8">
              <p className="mb-0">
                {JoinString(user.first_name, user.last_name)}
              </p>
            </Col>
            <Col xs={2} md="1">
              <i className="material-icons clickable-icon" onClick={toggle}>
                create_outline
              </i>
            </Col>
          </Row>

          <Row className="border-bottom py-3 align-items-center">
            <Col xs={12} md="3">
              <p className="color-light mb-0">Profile Photo</p>
            </Col>
            <Col xs={10} md="8">
              <img
                className="image-rounded ml-2 mr-1"
                src={NoPhotoSVG}
              />
            </Col>
            <Col xs={2} md="1">
              <i className="material-icons clickable-icon" onClick={toggle}>
                create_outline
              </i>
            </Col>
          </Row>

          <Row className="border-bottom py-3 align-items-center">
            <Col md="3">
              <p className="color-light mb-0">Phone Number</p>
            </Col>
            <Col xs={10} md="8">
              <p className="mb-0">
                {JoinString(user.countryCode, user.mobile_no)}
              </p>
            </Col>
            <Col xs={2} md="1">
              <i className="material-icons clickable-icon" onClick={toggle}>
                create_outline
              </i>
            </Col>
          </Row>

          <Row className="py-3 align-items-center">
            <Col md="3">
              <p className="color-light mb-0">Email</p>
            </Col>
            <Col xs={10} md="8">
              <p className="mb-0">{user.email}</p>
            </Col>
            <Col xs={2} md="1">
              <i className="material-icons clickable-icon">lock_outline</i>
            </Col>
          </Row>
        </Col>
      </Row>
      <UpdateUserProfile modal={modal} toggle={toggle} userDetails={user} />
    </Container>
  );
};
