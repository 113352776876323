import React from "react";
import { Provider } from "react-redux";
import StorageApp from "./storage-app";
import store from "../redux/store";
import MainNavbar from "./navbar/main-navbar";
import UploadWindow from "../components/upload/upload-window/upload-window";

const StorageAppContainer = (props) => {
  return (
    <Provider store={store}>
      <MainNavbar></MainNavbar>
      <div className="content ">
        <StorageApp {...props} />
      </div>
      <UploadWindow />
    </Provider>
  );
};

export default StorageAppContainer;
