import {
  Document,
  Packer,
  Paragraph,
  Table,
  TableRow,
  TableCell,
  ImageRun,
  AlignmentType,
  TextRun,
  Footer,
  HeadingLevel,
  VerticalAlign,
} from "docx";
import html2canvas from "html2canvas";

const downloadDOCX = async (
  pageRef,
  files,
  showIndex,
  footerText,
  setProgress
) => {
  const doc = new Document({
    sections: [],
  });
  const element = pageRef.current;
  const children = Array.from(element.children);
  const totalSteps = children.length + (showIndex ? 1 : 0);
  let currentStep = 0;

  const updateProgress = () => {
    currentStep++;
    const progressPercentage = Math.round((currentStep / totalSteps) * 100);
    setProgress(progressPercentage);
  };

  if (showIndex) {
    const today = new Date()
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");

    const indexTable = new Table({
      width: {
        size: 100,
        type: "pct",
      },
      alignment: AlignmentType.CENTER,
      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  text: "Sr. No.",
                  alignment: AlignmentType.CENTER,
                }),
              ],
              width: { size: 15, type: "pct" },
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Image",
                  alignment: AlignmentType.CENTER,
                }),
              ],
              width: { size: 70, type: "pct" },
              verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
              children: [
                new Paragraph({
                  text: "Page No.",
                  alignment: AlignmentType.CENTER,
                }),
              ],
              width: { size: 15, type: "pct" },
              verticalAlign: VerticalAlign.CENTER,
            }),
          ],
          height: {
            value: 600,
            rule: "atLeast",
          },
        }),
        ...files.map(
          (file, index) =>
            new TableRow({
              children: [
                new TableCell({
                  children: [
                    new Paragraph({
                      text: `${index + 1}`,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      children: [
                        new TextRun({
                          text:
                            file.alias && file.alias.trim() !== ""
                              ? file.alias
                              : file.name,
                          color: "0000FF",
                        }),
                      ],
                      alignment: AlignmentType.LEFT,
                      indent: { left: 360 },
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                  children: [
                    new Paragraph({
                      text: `${index + 1}`,
                      alignment: AlignmentType.CENTER,
                    }),
                  ],
                  verticalAlign: VerticalAlign.CENTER,
                }),
              ],
              height: {
                value: 400,
                rule: "atLeast",
              },
            })
        ),
      ],
    });

    const indexSection = {
      properties: {
        page: {
          margin: {
            top: 1000,
            right: 1000,
            bottom: 1000,
            left: 1000,
          },
        },
      },
      children: [
        new Paragraph({
          text: `Date: ${today}`,
          alignment: AlignmentType.RIGHT,
        }),
        new Paragraph({
          text: "Index",
          heading: HeadingLevel.HEADING_1,
          alignment: AlignmentType.CENTER,
        }),
        new Paragraph(""),
        new Paragraph(""),
        indexTable,
      ],
    };

    doc.addSection(indexSection);
    updateProgress();
  }

  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    const canvas = await html2canvas(child, {
      scale: 2,
      useCORS: true,
    });

    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/jpeg", 1.0)
    );
    const arrayBuffer = await blob.arrayBuffer();

    const maxWidth = 600;
    const maxHeight = 800;
    let width = canvas.width;
    let height = canvas.height;
    const aspectRatio = width / height;

    if (width > maxWidth) {
      width = maxWidth;
      height = width / aspectRatio;
    }
    if (height > maxHeight) {
      height = maxHeight;
      width = height * aspectRatio;
    }

    doc.addSection({
      properties: {
        page: {
          margin: {
            top: 1000,
            right: 1000,
            bottom: 1000,
            left: 1000,
          },
        },
      },
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: arrayBuffer,
              transformation: {
                width,
                height,
              },
            }),
          ],
        }),
      ],
      footers: {
        default: new Footer({
          children: [
            new Paragraph({
              text:
                showIndex && footerText
                  ? `Page ${i + 1} | ${footerText}`
                  : `Page ${i + 1}`,
              alignment: AlignmentType.RIGHT,
            }),
          ],
        }),
      },
    });
    updateProgress();
  }

  const blob = await Packer.toBlob(doc);
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "download.docx";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export default downloadDOCX;
