import React from "react";
import { Button } from "reactstrap";

const PlusButton = ({ label, onClick }) => {
  const buttonId = "id_" + label.replace(/\s+/g, "-").toLowerCase(); // Replace spaces with hyphens

  return (
    <>
      <Button
        id={buttonId}
        color="link"
        className="plus-button"
        onClick={onClick}
      >
        <i className="material-icons">add_box</i>
        {label}
      </Button>
    </>
  );
};

export default PlusButton;
