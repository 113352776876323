import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Input,
  FormFeedback,
} from "reactstrap";
import { createFolderAction } from "../../../redux/slices/folders-slice";

const NewFolderModal = ({ isOpen, toggle }) => {
  const [folderName, setFolderName] = useState("Untitled Folder");
  const [nameExists, setNameExists] = useState(false);
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activeFolder = useSelector((state) => state.activeFolder);
  const folders = useSelector((state) => state.folders.folders);

  useEffect(() => {
    if (isOpen) {
      setFolderName("Untitled Folder"); // Reset folder name
      setNameExists(false);
      const timer = setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.select();
        }
      }, 0);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const checkNameExists = (name) => {
    const currentFolders = activeFolder.activeFolderId
      ? activeFolder.folders
      : folders;
    return currentFolders.some((folder) => folder.folder_name === name);
  };

  const handleNameChange = (e) => {
    const newName = e.target.value;
    setFolderName(newName);
    setNameExists(checkNameExists(newName));
  };

  const createFolder = () => {
    //To Review :- instead of double dispatch i have used this logic to use only once
    if (nameExists) return;

    const newFolder = {
      org_id: user.org_id,
      folder_name: folderName,
      user_id: user.id,
    };

    if (activeFolder && activeFolder.activeFolderId) {
      newFolder.parent_folder_id = activeFolder.activeFolderId;
    }

    dispatch(
      createFolderAction({
        folder: newFolder,
        successHandler: () => {
          toggle();
        },
      })
    );
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" && !isCreateDisabled) {
      e.preventDefault();
      createFolder();
    }
  };

  const isCreateDisabled = folderName.trim() === "" || nameExists;

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} className="custom-modal">
      <ModalHeader className="border-bottom-0" toggle={toggle}>
        New Folder
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            type="text"
            name="folderName"
            id="folderName"
            placeholder="Enter folder name"
            value={folderName}
            onChange={handleNameChange}
            onKeyDown={handleInputKeyPress}
            innerRef={inputRef}
            invalid={nameExists}
          />
          <FormFeedback>A folder with this name already exists.</FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={toggle}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={createFolder}
          disabled={isCreateDisabled}
        >
          Create
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewFolderModal;
