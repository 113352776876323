import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIEW_MODES } from "../../constants/view-modes";
import {
  fetchActiveFolderBreadcrumb,
  fetchFilesInFolder,
  fetchFolderContents,
  getStatusFetchFilesInFolder,
  getStatusFetchFolderContents,
  setActiveFolderId,
} from "../../redux/slices/active-folder-slice";
import { useLocation } from "react-router-dom";
import FolderThumbnails from "./folder-thumbnails";
import FileThumbnails from "./file-thumbnails";
import EmptyState from "../common/empty-state";
import { API_STATUS } from "../../constants/api-status";

const Folder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const organization = useSelector((state) => state.organization);
  const viewMode = useSelector((state) => state.userPreference.viewMode);
  const activeFolder = useSelector((state) => state.activeFolder);
  const statusFetchFolderContents = useSelector(getStatusFetchFolderContents);
  const statusFetchFilesInFolder = useSelector(getStatusFetchFilesInFolder);

  const checkAndFetchFiles = useCallback(() => {
    if (organization && organization.id && activeFolder.activeFolderId) {
      dispatch(
        fetchFilesInFolder({
          orgId: organization.id,
          folderId: activeFolder.activeFolderId,
        })
      );
    }
  }, [dispatch, organization, activeFolder.activeFolderId]);

  useEffect(() => {
    // Parse the search string to get query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if 'folderId' query parameter exists
    if (searchParams.has("folderId") && organization && organization.id) {
      // Get the value of 'folderId' query parameter
      const folderId = searchParams.get("folderId");

      dispatch(setActiveFolderId(folderId));

      dispatch(fetchFolderContents({ orgId: organization.id, folderId }));
      dispatch(fetchFilesInFolder({ orgId: organization.id, folderId ,userId:user.id}));
      dispatch(fetchActiveFolderBreadcrumb({ folderId }));
    }
  }, [organization, location, dispatch]);

  // useEffect(() => {
  //   if (activeFolder.files && activeFolder.files.length > 0) {
  //     const hasUnprocessedFiles = activeFolder.files.some(
  //       (file) => !file.is_OCR
  //     );

  //     if (hasUnprocessedFiles) {
  //       const timeoutId = setTimeout(() => {
  //         checkAndFetchFiles();
  //       }, 120000); // 2 minutes timeout
  //       return () => clearTimeout(timeoutId);
  //     }
  //   }
  // }, [activeFolder.files, checkAndFetchFiles]);

  return statusFetchFolderContents == API_STATUS.SUCCESS &&
    statusFetchFilesInFolder == API_STATUS.SUCCESS ? (
    (activeFolder.folders && activeFolder.folders.length > 0) ||
    (activeFolder.files && activeFolder.files.length > 0) ? (
      viewMode === VIEW_MODES.THUMBNAILS ? (
        <>
          <FolderThumbnails folders={activeFolder.folders} />
          <FileThumbnails files={activeFolder.files} />
        </>
      ) : (
        <EmptyState text="Coming Soon. Please check back later." />
      )
    ) : (
      <EmptyState text="Folder is empty." />
    )
  ) : (
    <></>
  );
};

export default Folder;
