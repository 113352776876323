import { createSlice } from "@reduxjs/toolkit";
import { VIEW_MODES } from "../../constants/view-modes";

const initialState = {
  viewMode: VIEW_MODES.THUMBNAILS,
};

const userPreferenceSlice = createSlice({
  name: "userPreference",
  initialState,
  reducers: {
    setViewMode: (state, action) => {
      return { ...state, viewMode: action.payload };
    },
  },
});

export const { setViewMode } = userPreferenceSlice.actions;
export default userPreferenceSlice.reducer;
