import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EmptyState from "../components/common/empty-state";
import FileThumbnails from "../components/folders/file-thumbnails";
import FolderThumbnails from "../components/folders/folder-thumbnails";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  getSeachResultFiles,
  getSeachResultfolders,
  searchFilesAction,
} from "../redux/slices/search-slice";

const SearchView = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector(state=>state.user)
  const organization = useSelector((state) => state.organization);
  const searchFiles = useSelector(getSeachResultFiles);
  const searchFolders = useSelector(getSeachResultfolders);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get("q");
    const type = queryParams.get("type") ? queryParams.get("type") : "all";
    const tags = queryParams.get("tags")?.split(",") || [];
    const after = queryParams.get("after");
    const before = queryParams.get("before");

    if (organization.id != null) {
      setIsLoading(true);
      dispatch(
        searchFilesAction({
          filters: {
            searchKey: query,
            type,
            tags,
            startDate: after,
            endDate: before,
            org_id: organization.id,
            language: "marathi",
          },
          userId:user.id,
          successHandler: () => {
            setIsLoading(false);
          },
        })
      );
    }
  }, [location, organization.id]);

  return (
    <>
      <StorageNavbar filters={["tags", "type", "date"]} />
      <div className="storage-content">
        {!isLoading ? (
          (searchFiles && searchFiles.length) ||
          (searchFolders && searchFolders.length) ? (
            <>
              <FolderThumbnails folders={searchFolders} />
              <FileThumbnails files={searchFiles} />
            </>
          ) : (
            <EmptyState text={"No matching results."} />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default SearchView;
