import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchOrganization } from '../services/auth-service';

const initialState = {
  id: "6623f91feb48977893e9691c",
  name: "SDYP",
  key: "SDYP",
  description: "Banti Patil Demo",
  created_at: "2024-04-20T17:19:27.292Z",
  created_by: "admin",
  storage: {
    available: 0,
    orgId: "",
    total: 100,
    isLoad: true,
    percentage: 0
  }
};


export const fetchOrganizationAction = createAsyncThunk(
  "user/organization",
  async ({ orgId, successHandler, errorHandler }, { dispatch }) => {
    const data = await fetchOrganization(orgId, successHandler, errorHandler);
    dispatch(updateOrganization(data.organization)); // Assuming organization data is included in the response
    return data.organization; // Return user data for updating the state
  }
);

const organizationSlice = createSlice({
  name: 'organization',
  initialState:{},
  reducers: {
    updateOrganization: (state, action) => {
      if(state.id == action.payload._id){
        return state
      }
      
      return {
        ...state,
        ...action.payload,
        id:action.payload._id,
      };
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationAction.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchOrganizationAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the state with user data
        state= {
          ...state,
          ...action.payload,
        };
      })
      .addCase(fetchOrganizationAction.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { updateOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
