import React from "react";
import EmptyStateNoData from "../../../../assets/images/empty-state-no-data.svg";

const EmptyState = ({ text }) => {
  return (
    <div className="empty-state no-select">
      <div className="empty-state-content">
        <div
          className="empty-state-image"
          style={{
            backgroundImage: `url(${EmptyStateNoData})`,
          }}
        ></div>
        <p className="empty-state-text">{text}</p>
      </div>
    </div>
  );
};

export default EmptyState;
