import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../components/common/empty-state";
import FileThumbnails from "../components/folders/file-thumbnails";
import FolderThumbnails from "../components/folders/folder-thumbnails";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import { VIEW_MODES } from "../constants/view-modes";
import {
  fetchTrashContentsAction,
  getTrashFiles,
  getTrashFolders,
} from "../redux/slices/trash-slice";

const TrashView = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const organization = useSelector((state) => state.organization);
  const viewMode = useSelector((state) => state.userPreference.viewMode);

  const trashFiles = useSelector(getTrashFiles);
  const trashFolders = useSelector(getTrashFolders);

  useEffect(() => {
    if (organization && organization.id) {
      setIsLoading(true);
      dispatch(fetchTrashContentsAction({ orgId: organization.id }))
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          // ToDo: Error handling
        });
    }
  }, [organization.id]);

  return (
    <>
      <StorageNavbar />
      <div className="storage-content">
        {!isLoading ? (
          trashFolders?.length > 0 || trashFiles?.length > 0 ? (
            viewMode === VIEW_MODES.THUMBNAILS ? (
              <>
                <FolderThumbnails folders={trashFolders} />
                <FileThumbnails files={trashFiles} />
              </>
            ) : (
              <EmptyState text="Coming Soon. Please check back later." />
            )
          ) : (
            <EmptyState text="Trash is empty." />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default TrashView;
