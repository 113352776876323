import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  FormGroup,
  Input,
  Button,
  Form,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AddUsers = ({ modal, toggle }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const subOrganizations = useSelector((state) => state.subOrganizations);



  const handleAdd = () => {
    const [countryCode, mobileNumber] = phoneNumber.split(" ");
    // addOrganizationUser({
    //   variables: {
    //     userId: undefined,
    //     firstName: firstName,
    //     lastName: lastName,
    //     email: email,
    //     countryCode: countryCode,
    //     mobileNumber: mobileNumber,
    //   },
    // });

    toggle();
  };

  const handleInputChange = (phone) => {
    setPhoneNumber(phone);
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} className={"custom-modal"}>
      <ModalHeader toggle={toggle}>
        Add User
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstname">
             First Name
            </Label>
            <Input
              type="text"
              name="firstname"
              id="firstname"
              placeholder="Enter First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastname">
             Last Name
            </Label>
            <Input
              type="text"
              name="lastname"
              id="lastname"
              placeholder="Enter Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">
             Email
            </Label>
            <Input
              type="email"
              name="email"
              id="email"
              placeholder="email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="usernumber">
             Phone Number
            </Label>
         
          </FormGroup>
      
          
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => handleAdd()}>
          Add User
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddUsers;
