import React, { useEffect, useState } from "react";
import { SHORTCUTS } from "../../../../constants/shortcuts";
import EditableTextCard from "../../../common/editable-text-card";

const NoteComponent = ({ initialNote, onSave }) => {
  const [note, setNote] = useState(initialNote);

  const handleSave = (note) => {
    onSave(note);
  };

  useEffect(() => {
    setNote(initialNote);
  }, [initialNote]);
  return (
    <EditableTextCard
      title="Note"
      initialText={note}
      editable={true}
      onSave={handleSave}
      placeholder="Type your note here..."
      shortcutKey={SHORTCUTS.EDIT_NOTE}
    />
  );
};

export default NoteComponent;
