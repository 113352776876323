// store.js
import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/user-slice';
import organizationSlice from './slices/organization-slice';

let store;

export const getStore =()=>{
  if(!store){
    store = configureStore({
      reducer: {
       user:userSlice,
       organization:organizationSlice
      },
    });
  }
  return store
}

export default store;