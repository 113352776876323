import React, { useEffect } from "react";
import { json, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { AuthLogin } from "../components/auth/auth-login";

const AuthContainer = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.user && props.user.id ) {
      navigate("/");
      window.location.reload()
    }
  }, [props.user]);

  return (
    <Container fluid={true}>
      <Row>
        <Col sm={12} md={6} className="m-auto">
          <Routes>
            <Route path="login" element={<AuthLogin {...props} />}></Route>
            <Route path="*" element={<Navigate to="/auth/login" replace />} />
            {/* <Route
              path="reset-password"
              element={<AuthResetPassword />}
            ></Route>
            <Route
              path="submit-request"
              element={<AuthSubmitRequest />}
            ></Route> */}
            {/* <Route ="/auth/login" /> */}
          </Routes>
        </Col>
        {/* <Col sm={12} md={6}>
          <LoginCarousel />
        </Col> */}
      </Row>
    </Container>
  );
};

export default AuthContainer;
