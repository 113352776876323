import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse, Nav, NavbarText } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
// import { useSelector } from "react-redux";
// import SidebarFooter from "./sidebar-footer";
import logo from "../../assets/images/organization_default_thumbnail.svg";
import { useSelector } from "react-redux";
import SidebarMenu from "./components/sidebar-menu";

let ps;

const Sidebar = ({ bgColor, activeColor, routes, isMobile }) => {
  const sidebarRef = useRef(null);
  const organization = useSelector((state) => state.organization);
  const location = useLocation();
  // Below code is causing scrolling issues on sidebar. So commenting for now.
  // useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebarRef.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return () => {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // }, []);

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const [collapseState, setCollapseState] = useState(getCollapseStates(routes));
  const sidebar = useRef(null);

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  const activeRoute = (routeName) => {
    return location.pathname == routeName ? "active" : "";
  };

  const createLinks = (routes) => {
    return routes
      .filter((route) => route.showInSidebar)
      .map((menu, key) => (
        <SidebarMenu
          menu={menu}
          key={key}
          activeRoute={activeRoute}
          setCollapseState={setCollapseState}
          getCollapseInitialState={getCollapseInitialState}
          createLinks={createLinks}
          collapseState={collapseState}
          toggleSidebar={toggleSidebar}
          isMobile={isMobile}
        />
      ));
  };

  return (
    <div
      className="sidebar"
      data-color={bgColor}
      data-active-color={activeColor}
    >
      <div className="logo">
        <a href="" className="simple-text logo-mini">
          <div className="logo-img">
            <img
              src={logo}
              alt="react-logo"
              style={{ objectFit: "scale-down" }}
            />
          </div>
        </a>
        <a href="" className="simple-text logo-normal org-name">
          <span className="title">{organization ? organization.name : ""}</span>
          {/* <span className="subtitle">Proxima Smart Storage</span> */}
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebarRef}>
        <Nav>{createLinks(routes)}</Nav>
        {/* <SidebarFooter/> */}
      </div>
    </div>
  );
};

export default Sidebar;
