import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../constants/api-status";
import {
  createFolder,
  fetchFolderHierarchy,
  fetchFoldersApi,
} from "../services/folder-service";
import { moveFolderAction, renameFolder } from "./active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";
import { moveFolderToTrashAction } from "./trash-slice";

const initialState = {
  status: "",
  folders: [],
  sharedStorageFolderHierarchy: null,
  myStorageFolderHierarchy: null,
  // Status : Loading, Success, Error
  statusFetchFolders: "",
  statusFetchSharedStorageFolderHierarchy: "",
  statusFetchMyStorageFolderHierarchy: "",
  error: "",
};

export const fetchFolders = createAsyncThunk(
  "folders/parent",
  async ({ orgId, successHandler }, { dispatch }) => {
    const data = await fetchFoldersApi(orgId, successHandler);
    return data; // Return user data for updating the state
  }
);

export const fetchSharedStorageFolderHierarchy = createAsyncThunk(
  "folders/sharedStorageFolderHierarchy",
  async (orgId, { dispatch }) => {
    const data = await fetchFolderHierarchy(orgId, "shared_storage");
    return data.hierarchy; // Return user data for updating the state
  }
);

export const fetchMyStorageFolderHierarchy = createAsyncThunk(
  "folders/myStorageFolderHierarchy",
  async (orgId, { dispatch }) => {
    const data = await fetchFolderHierarchy(orgId, "my_storage");
    return data; // Return user data for updating the state
  }
);

export const createFolderAction = createAsyncThunk(
  "folders/create",
  async ({ folder, successHandler }, { dispatch }) => {
    const data = await createFolder(folder, successHandler);
    return { data: data, folder: folder }; // Return user data for updating the state
  }
);

const foldersSlice = createSlice({
  name: "folders",
  initialState,
  reducers: {
    setFolders: (state, action) => {
      return {
        ...state,
        folders: [...action.payload],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for : fetchFolders
      // ====================
      .addCase(fetchFolders.pending, (state) => {
        state.statusFetchFolders = API_STATUS.LOADING;
      })
      .addCase(fetchFolders.fulfilled, (state, action) => {
        // Update the state with user data
        state.folders = [...action.payload.folders];
        state.statusFetchFolders = API_STATUS.SUCCESS;
      })
      .addCase(fetchFolders.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchFolders = API_STATUS.ERROR;
      })

      // ====================
      // Extra Reducers for : fetchSharedStorageFolderHierarchy
      // ====================
      .addCase(fetchSharedStorageFolderHierarchy.pending, (state) => {
        state.statusFetchSharedStorageFolderHierarchy = API_STATUS.LOADING;
      })
      .addCase(fetchSharedStorageFolderHierarchy.fulfilled, (state, action) => {
        state.sharedStorageFolderHierarchy = action.payload;
        state.statusFetchSharedStorageFolderHierarchy = API_STATUS.SUCCESS;
      })
      .addCase(fetchSharedStorageFolderHierarchy.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchSharedStorageFolderHierarchy = API_STATUS.ERROR;
      })

      // ====================
      // Extra Reducers for : createFolderAction
      // ====================
      .addCase(createFolderAction.fulfilled, (state, action) => {
        // After succes, add folder to folders list
        if (action.payload.folder != null) {
          state.folders = [
            ...state.folders,
            {
              ...action.payload.folder,
              _id: action.payload.data.folder_id,
            },
          ];
        }
      })

      // ====================
      // Extra Reducers for : moveFolderAction from active-folder-slice
      // ====================
      .addCase(moveFolderAction.fulfilled, (state, action) => {
        // After succes, remove folder from folders list
        if (action.payload.newParentFolderId != null) {
          state.folders = state.folders.filter(
            (folder) => folder._id !== action.payload.folderId
          );
        }
      })

      // ====================
      // Extra Reducers for : renameFolder from active-folder-slice
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        // Rename is success, change folder name in state
        state.folders = state.folders.map((folder) =>
          folder._id === action.payload.folderId
            ? { ...folder, folder_name: action.payload.newName }
            : folder
        );
      })

      // ====================
      // Extra Reducers for : Favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;
        // Add to folders
        if (itemType && itemType === "folder") {
          state.folders = state.folders.map((folder) =>
            folder._id === itemId ? { ...folder, is_favorite: true } : folder
          );
        }
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;
        // Remove from folders
        if (itemType && itemType === "folder") {
          state.folders = state.folders.map((folder) =>
            folder._id === itemId ? { ...folder, is_favorite: false } : folder
          );
        }
      })

      // ====================
      // Extra Reducers for : Trash
      // ====================
      .addCase(moveFolderToTrashAction.fulfilled, (state, action) => {
        state.folders = state.folders.filter(
          (folder) => folder._id !== action.payload.folder._id
        );
      });
  },
});

export const { setFolders } = foldersSlice.actions;

export const getStatusFetchFolders = (state) =>
  state.folders.statusFetchFolders;
export const getSharedStorageFolderHierarchy = (state) =>
  state.folders.sharedStorageFolderHierarchy;
export const getMyStorageFolderHierarchy = (state) =>
  state.folders.myStorageFolderHierarchy;

export default foldersSlice.reducer;
