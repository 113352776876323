// Middleware function to handle HTTP errors
export const handleErrors = async (response, errorHandler) => {
  if (!response.ok) {
    const errorData = await response.json();
    if (errorHandler) {
      errorHandler(errorData.message || "Network response was not ok");
    }
    throw new Error(errorData.message || "Network response was not ok");
  }
  return response;
};

// Middleware function to parse JSON
export const parseJSON = async (response) => {
  const data = await response.json();
  return data;
};

// Function to create headers for fetch requests
export const createHeaders = (additionalHeaders = {}) => {
  const headers = {
    "Content-Type": "application/json",
    // Add more default headers here if needed
    ...additionalHeaders,
  };

  // Example: Set an Authorization header if a token is available
  const token = localStorage.getItem("authToken"); // or use a state management solution
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  return headers;
};

const cache={};
export const storeInCache = (key,value)=>{
  cache[key]=value;
}

export const retriveFromCache =(key)=>{
    return cache[key]
}

export const deleteFromCache = (key)=>{
  delete cache[key]
}

