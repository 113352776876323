import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const fetchTrashContents = async (orgId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/trash/${orgId}`, {
      method: "GET",
      headers: createHeaders(),
    });

    await handleErrors(response);
    const data = await parseJSON(response);

    const sanitizeDate = (dateString) => {
      if (!dateString) return null;
      const date = new Date(dateString);
      return isNaN(date.getTime()) ? null : date.toISOString();
    };

    const transformedData = {
      ...data,
      trashed_images: data.trashedImages.map((image) => ({
        ...image,
        folder_id: image.folder_id || "trash",
        _id: image.image_id,
        created_at: sanitizeDate(image.created_at),
        updated_at: sanitizeDate(image.updated_at),
        deleted_at: sanitizeDate(image.deleted_at),
        lang: image.language,
      })),
      trashed_folders: data.trashedFolders.map((folder) => ({
        ...folder,
        folder_name: folder.name,
        _id: folder.folder_id,
        is_bin: folder.is_bin,
        created_at: sanitizeDate(folder.created_at),
        updated_at: sanitizeDate(folder.updated_at),
        deleted_at: sanitizeDate(folder.deleted_at),
      })),
    };

    console.log(transformedData)
    return transformedData;
  } catch (error) {
    console.error("Error fetching trash contents:", error);
    throw error;
  }
};

export const restoreFromTrashService = async (orgId, itemId, itemType) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/trash/restore/${orgId}`,
      {
        method: "POST",
        headers: createHeaders(),
        body: JSON.stringify({
          [itemType === "folder" ? "folder_id" : "image_id"]: itemId,
        }),
      }
    );

    await handleErrors(response);
    const data = await parseJSON(response);
    return data;
  } catch (error) {
    console.error("Error restoring item from trash:", error);
    throw error;
  }
};

export const emptyTrashService = async (
  orgId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/trash/empty/${orgId}`, {
      method: "POST",
      headers: createHeaders(),
    });

    await handleErrors(response);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    console.error("Error emptying trash:", error);
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const deleteFolderForeverService = async (
  folderId,
  userId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folders/${folderId}`, {
      method: "DELETE",
      headers: createHeaders(),
      body: JSON.stringify({ user_id: userId }),
    });

    await handleErrors(response);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    console.error("Error deleting folder forever:", error);
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const deleteImageForeverService = async (
  imageId,
  language,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/images/${imageId}?language=${language}`,
      {
        method: "DELETE",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    console.error("Error deleting image forever:", error);
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
