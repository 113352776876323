export default class Text {
  constructor(x, y, text, color, fontSize = 16) {
    this.x = x;
    this.y = y;
    this.text = text;
    this.color = color;
    this.fontSize = fontSize;
    this.isSelected = false;
    this.width = 0;
    this.height = 0;
    this.originalFontSize = fontSize;
  }

  draw(ctx) {
    ctx.save();
    const scaledFontSize =
      this.originalFontSize / Math.abs(ctx.getTransform().a);
    ctx.font = `${scaledFontSize}px Arial`;
    ctx.fillStyle = this.color;
    ctx.fillText(this.text, this.x, this.y);

    const metrics = ctx.measureText(this.text);
    this.width = metrics.width;
    this.height = scaledFontSize;

    ctx.restore();
    if (this.isSelected) {
      ctx.save();
      ctx.strokeStyle = "#00ff00";
      ctx.lineWidth = 2 / ctx.getTransform().a;
      ctx.setLineDash([5, 5]);
      ctx.strokeRect(
        this.x - 2,
        this.y - this.height,
        this.width + 4,
        this.height + 4
      );
      ctx.restore();
    }
  }

  isPointInside(x, y) {
    return (
      x >= this.x - 2 &&
      x <= this.x + this.width + 2 &&
      y >= this.y - this.height - 2 &&
      y <= this.y + 2
    );
  }

  setSelected(selected) {
    this.isSelected = selected;
  }

  move(dx, dy) {
    this.x += dx;
    this.y += dy;
  }
}
