import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";

const UploadFolderGroupCard = ({ folderId }) => {
  return (
    <>
      <Card className="file-upload-folder-group">
        <CardBody className="d-flex align-items-center">
          {/* File Icon */}
          <i className="material-icons folder-icon">folder</i>
          <div className="file-details">
            {/* Folder Name */}
            <CardTitle className="mb-0 folder-name">{folderId}</CardTitle>
          </div>
          {/* <i className="material-icons">chevron_right</i> */}
        </CardBody>
      </Card>
    </>
  );
};

export default UploadFolderGroupCard;
