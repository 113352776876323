export default class Circle {
  constructor(centerX, centerY, radius, color, width = 2, imageBounds) {
    this.centerX = centerX;
    this.centerY = centerY;
    this.radius = radius;
    this.color = color;
    this.width = width;
    this.isSelected = false;
    this.imageBounds = imageBounds;
    this.originalWidth = width;
  }

  draw(ctx) {
    ctx.save();
    ctx.beginPath();
    ctx.arc(this.centerX, this.centerY, this.radius, 0, 2 * Math.PI);
    ctx.strokeStyle = this.color;
    ctx.lineWidth = this.originalWidth / Math.abs(ctx.getTransform().a);
    ctx.stroke();
    ctx.restore();

    if (this.isSelected) {
      ctx.save();
      ctx.strokeStyle = "#00ff00";
      ctx.lineWidth = 2 / ctx.getTransform().a;
      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.arc(this.centerX, this.centerY, this.radius + 2, 0, 2 * Math.PI);
      ctx.stroke();
      ctx.restore();
    }
  }

  updateRadius(newRadius) {
    const { x, y, width, height } = this.imageBounds;
    const maxRadius = Math.min(
      this.centerX - x,
      y + height - this.centerY,
      width - (this.centerX - x),
      this.centerY - y
    );
    this.radius = Math.min(newRadius, maxRadius);
  }

  isPointInside(x, y) {
    const dx = x - this.centerX;
    const dy = y - this.centerY;
    return dx * dx + dy * dy <= this.radius * this.radius;
  }

  setSelected(selected) {
    this.isSelected = selected;
  }

  move(dx, dy) {
    this.centerX += dx;
    this.centerY += dy;
  }
}
