import React from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

const UploadFileCard = ({ file, onCancelUpload }) => {
  return (
    <Card className="file-upload-card">
      <CardBody className="d-flex align-items-center">
        {/* File Icon */}
        <i className="material-icons file-icon">image</i>

        <div className="file-details">
          {/* File Name */}
          <CardTitle className="mb-0 file-name">
            {file.file.name || file.file.path}
          </CardTitle>
          {/* Conditional Error Text */}
          {file.hasError && <div className="error-text">Failed to upload</div>}
        </div>

        {/* Conditional Icon/Button */}
        {file.isUploading || file.isInQueue ? (
          <i
            className="material-icons icon-button"
            onClick={() => onCancelUpload(file)}
          >
            close
          </i>
        ) : (
          <i
            className={`material-icons file-status-icon ml-auto ${
              file.hasError ? "error" : "success"
            }`}
          >
            {file.hasError ? "error" : "check_circle"}
          </i>
        )}
      </CardBody>

      {/* Progress Bar */}
      {file.isUploading && (
        <Progress value={file.progress} className="upload-progress" />
      )}
    </Card>
  );
};

export default UploadFileCard;
