import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import {
  emptyTrashAction,
  getTrashFiles,
  getTrashFolders,
} from "../../redux/slices/trash-slice";
import ConfirmationModal from "../common/confirmation-modal";

const EmptyTrashButton = () => {
  const dispatch = useDispatch();

  const [confirmationModal, setConfirmationModal] = useState(false);

  const organization = useSelector((state) => state.organization);
  const trashFiles = useSelector(getTrashFiles);
  const trashFolders = useSelector(getTrashFolders);

  const toggleModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleConfirm = () => {
    if (organization && organization.id) {
      dispatch(
        emptyTrashAction({
          orgId: organization.id,
          successHandler: () => {
            toggleModal();
          },
        })
      );
    }
  };

  return (
    <>
      <Button
        color="primary"
        onClick={toggleModal}
        disabled={
          trashFolders?.length > 0 || trashFiles?.length > 0 ? false : true
        }
      >
        Empty Trash
      </Button>
      <ConfirmationModal
        isOpen={confirmationModal}
        toggle={toggleModal}
        title="Delete forever?"
        description="All items in the trash will be deleted forever and you won't be able to restore them."
        confirmButtonText="Delete forever"
        onConfirm={handleConfirm}
        isDanger={true}
      />
    </>
  );
};

export default EmptyTrashButton;
