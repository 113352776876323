import React from "react";
import TagsFilter from "./tags-filter";
import TypeFilter from "./type-filter";
import DateFilter from "./date-filter";
import { useLocation, useNavigate } from "react-router-dom";

const Filters = ({ filters, orgId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const tags = queryParams.get("tags");
  const after = queryParams.get("after");
  const before = queryParams.get("before");

  const handleClearFilters = () => {
    queryParams.delete("type"); // Clear the type filter from query parameters
    queryParams.delete("tags"); // Clear the tags filter from query parameters
    queryParams.delete("after"); // Clear the after date filter from query parameters
    queryParams.delete("before"); // Clear the before date filter from query parameters
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <div className="filter-container no-select">
        {filters.includes("type") && <TypeFilter />}
        {filters.includes("tags") && <TagsFilter orgId={orgId} />}
        {filters.includes("date") && <DateFilter />}
        {(type || tags || after || before) && (
          <div className="clear-filters-button" onClick={handleClearFilters}>
            Clear filters
          </div>
        )}
      </div>
    </>
  );
};

export default Filters;
