export default class Pixelate {
  constructor(startX, startY, pixelSize = 10, width = 20) {
    this.points = [{ x: startX, y: startY }];
    this.pixelSize = pixelSize;
    this.isSelected = false;
    this.strokeWidth = width;
  }

  addPoint(x, y) {
    this.points.push({ x, y });
  }

  draw(ctx) {
    if (this.points.length < 2) return;

    ctx.save();

    try {
      let minX = Math.min(...this.points.map((p) => p.x));
      let maxX = Math.max(...this.points.map((p) => p.x));
      let minY = Math.min(...this.points.map((p) => p.y));
      let maxY = Math.max(...this.points.map((p) => p.y));

      const padding = this.strokeWidth;
      minX -= padding;
      maxX += padding;
      minY -= padding;
      maxY += padding;

      const width = maxX - minX;
      const height = maxY - minY;

      const maskCanvas = document.createElement("canvas");
      maskCanvas.width = width;
      maskCanvas.height = height;
      const maskCtx = maskCanvas.getContext("2d");

      maskCtx.beginPath();
      maskCtx.moveTo(this.points[0].x - minX, this.points[0].y - minY);
      for (let i = 1; i < this.points.length; i++) {
        maskCtx.lineTo(this.points[i].x - minX, this.points[i].y - minY);
      }
      maskCtx.strokeStyle = "#fff";
      maskCtx.lineWidth = this.strokeWidth;
      maskCtx.lineCap = "round";
      maskCtx.lineJoin = "round";
      maskCtx.stroke();

      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = width;
      tempCanvas.height = height;
      const tempCtx = tempCanvas.getContext("2d");

      tempCtx.drawImage(
        ctx.canvas,
        minX,
        minY,
        width,
        height,
        0,
        0,
        width,
        height
      );

      const pixelCanvas = document.createElement("canvas");
      const pixelSize = this.pixelSize / ctx.getTransform().a;
      const pixelatedWidth = Math.max(1, Math.floor(width / pixelSize));
      const pixelatedHeight = Math.max(1, Math.floor(height / pixelSize));
      pixelCanvas.width = pixelatedWidth;
      pixelCanvas.height = pixelatedHeight;
      const pixelCtx = pixelCanvas.getContext("2d");

      pixelCtx.imageSmoothingEnabled = false;
      tempCtx.imageSmoothingEnabled = false;
      ctx.imageSmoothingEnabled = false;

      pixelCtx.drawImage(tempCanvas, 0, 0, pixelatedWidth, pixelatedHeight);

      tempCtx.clearRect(0, 0, width, height);
      tempCtx.drawImage(pixelCanvas, 0, 0, width, height);

      tempCtx.globalCompositeOperation = "destination-in";
      tempCtx.drawImage(maskCanvas, 0, 0);

      ctx.drawImage(tempCanvas, minX, minY);

      if (this.isSelected) {
        ctx.strokeStyle = "#00ff00";
        ctx.lineWidth = 2 / ctx.getTransform().a;
        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(this.points[0].x, this.points[0].y);
        for (let i = 1; i < this.points.length; i++) {
          ctx.lineTo(this.points[i].x, this.points[i].y);
        }
        ctx.stroke();
      }
    } catch (error) {
      console.error("Pixelation error:", error);
    }

    ctx.restore();
  }

  isPointInside(x, y) {
    const threshold = this.strokeWidth / 2;
    for (let i = 1; i < this.points.length; i++) {
      const x1 = this.points[i - 1].x;
      const y1 = this.points[i - 1].y;
      const x2 = this.points[i].x;
      const y2 = this.points[i].y;
      const dx = x2 - x1;
      const dy = y2 - y1;
      const length = Math.sqrt(dx * dx + dy * dy);
      const dot = ((x - x1) * dx + (y - y1) * dy) / (length * length);
      const closestX = x1 + dot * dx;
      const closestY = y1 + dot * dy;
      const distanceSquared =
        (x - closestX) * (x - closestX) + (y - closestY) * (y - closestY);
      if (distanceSquared < threshold * threshold && dot >= 0 && dot <= 1) {
        return true;
      }
    }
    return false;
  }

  move(dx, dy) {
    this.points = this.points.map((point) => ({
      x: point.x + dx,
      y: point.y + dy,
    }));
  }

  setSelected(selected) {
    this.isSelected = selected;
  }
}
