import React from "react";
import OcrTextComponent from "./ocr-text-component";

const OcrTextTab = ({ file }) => {
  return (
    <div className="tab-content">
      <OcrTextComponent file={file} />
    </div>
  );
};

export default OcrTextTab;
