import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { Row, Col } from "reactstrap";

const items = [
  {
    src: "https://dashlite.themenio.com/demo2/images/slides/promo-a2x.png",
    altText: "Antridge Organization",
    caption: "Taking Organization Communication to Another level",
  },
  {
    src: "https://dashlite.themenio.com/demo2/images/slides/promo-b2x.png",
    altText: "Connect",
    caption: "Connect to Any one in your organizaion easily and on the go",
  },
  {
    src: "https://dashlite.themenio.com/demo2/images/slides/promo-c2x.png",
    altText: "Manage",
    caption: "Manage your organization using our easy-to-use Dashboard",
  },
];

export const LoginCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} width="100%" />
        <CarouselCaption
          captionText={item.caption}
          className="position-static text-dark"
          //  captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Row className="h-100 max-w-550 bg-light">
      <Col sm={12} md={10} lg={8} className="mx-auto align-self-center py-4">
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          {slides}
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            type="dots"
            onClickHandler={goToIndex}
            className="position-relative"
          />
        </Carousel>
      </Col>
    </Row>
  );
};
