import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

export const addToFavorites = async (
  orgId,
  userId,
  itemId,
  itemType,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/favorites`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify({
       orgId,
       userId,
        [itemType + "Id"]: itemId,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const removeFromFavorites = async (
  orgId,
  userId,
  itemId,
  itemType,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/favorites/remove`, {
      method: "DELETE",
      headers: createHeaders(),
      body: JSON.stringify({
        orgId,
         userId,
        [itemType + "Id"]: itemId,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const getAllFavorites = async (
  orgId,
  userId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/favorites/${orgId}/${userId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
