import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addToFavorites,
  getAllFavorites,
  removeFromFavorites,
} from "../services/favorite-service";
import { renameFile, renameFolder } from "./active-folder-slice";
import { moveFileToTrashAction, moveFolderToTrashAction } from "./trash-slice";

const initialState = {
  favorites: {
    folders: [],
    files: [],
  },
};

export const addToFavoritesAction = createAsyncThunk(
  "folder/addToFavorites",
  async ({ orgId, userId, itemId, itemType }, { dispatch }) => {
    const data = await addToFavorites(orgId, userId, itemId, itemType);
    return { data: data, itemId: itemId, itemType: itemType };
  }
);

export const removeFromFavoritesAction = createAsyncThunk(
  "folder/removeFromFavorites",
  async ({ orgId, userId, itemId, itemType }, { dispatch }) => {
    const data = await removeFromFavorites(orgId, userId, itemId, itemType);
    return { ...data, itemId, itemType };
  }
);

export const getAllFavoritesAction = createAsyncThunk(
  "folder/getAllFavorites",
  async ({ orgId, userId }, { dispatch }) => {
    const data = await getAllFavorites(orgId, userId);
    return data;
  }
);

const favoritesSlice = createSlice({
  name: "favorites",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // .addCase(addToFavoritesAction.fulfilled, (state, action) => {
      //   const { itemId, itemType } = action.payload;

      //   // Ensure favorites is defined
      //   if (!state.favorites) {
      //     state.favorites = { folders: [], files: [] };
      //   }

      //   // Add to files
      //   if (itemType && itemType === "image") {
      //     state.favorites.files = [
      //       ...state.favorites.files,
      //       { _id: itemId, is_favorite: true },
      //     ];
      //   }
      //   // Add to folders
      //   else if (itemType && itemType === "folder") {
      //     state.favorites.folders = [
      //       ...state.favorites.folders,
      //       { _id: itemId, is_favorite: true },
      //     ];
      //   }
      // })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;

        // Ensure favorites is defined
        if (!state.favorites) {
          return; // if favorites is undefined, no need to proceed
        }

        // Remove from files
        if (itemType && itemType === "image") {
          state.favorites.files = state.favorites.files.filter(
            (file) => file._id !== itemId
          );
        }
        // Remove from folders
        else if (itemType && itemType === "folder") {
          state.favorites.folders = state.favorites.folders.filter(
            (folder) => folder._id !== itemId
          );
        }
      })
      .addCase(getAllFavoritesAction.fulfilled, (state, action) => {
        state.favorites.files = action.payload.files;
        state.favorites.folders = action.payload.folders;
      })

      // ====================
      // Extra Reducers for : Rename
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        if (state.favorites) {
          state.favorites.folders = state.favorites.folders.map((folder) =>
            folder._id === action.payload.folderId
              ? { ...folder, folder_name: action.payload.newName }
              : folder
          );
        }
      })
      .addCase(renameFile.fulfilled, (state, action) => {
        if (state.favorites) {
          state.favorites.files = state.favorites.files.map((file) =>
            file._id === action.payload.fileId
              ? { ...file, name: action.payload.newName }
              : file
          );
        }
      })

      // ====================
      // Extra Reducers for Trash
      // ====================
      .addCase(moveFolderToTrashAction.fulfilled, (state, action) => {
        if (state.favorites) {
          state.favorites.folders = state.favorites.folders.filter(
            (folder) => folder._id !== action.payload.folder._id
          );
        }
      })
      .addCase(moveFileToTrashAction.fulfilled, (state, action) => {
        if (state.favorites) {
          state.favorites.files = state.favorites.files.filter(
            (file) => file._id !== action.payload.file._id
          );
        }
      });
  },
});

export const {} = favoritesSlice.actions;

export const getFavorites = (state) => state.favorites.favorites;

export default favoritesSlice.reducer;
