import React, { useState } from "react";
import { API_BASE_URL } from "../../../../redux/services/base-url";
import ImageOptions from "./image-options";

const ImageViewerPanel = ({
  files,
  currentFile,
  currentIndex,
  handleNext,
  handlePrevious,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [cursor, setCursor] = useState("default");

  // Handle when dragging starts
  const handleMouseDown = (e) => {
    if (zoomLevel <= 1) return;
    setIsDragging(true);
    setStartPos({
      x: e.clientX - position.x,
      y: e.clientY - position.y,
    });
    setCursor("move");
  };

  // Handle the dragging
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    setPosition({
      x: e.clientX - startPos.x,
      y: e.clientY - startPos.y,
    });
  };

  // Handle when dragging ends
  const handleMouseUp = () => {
    setIsDragging(false);
    setCursor("default");
  };

  // Handle zoom in
  const zoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.25); // Increase zoom by 20%
  };

  // Handle zoom out
  const zoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.25, 1)); // Decrease zoom by 20%, minimum zoom is 100%
  };

  // Reset zoom to 100%
  const resetZoom = () => {
    setZoomLevel(1); // Reset to default zoom level
    setPosition({ x: 0, y: 0 }); // Reset position when zoom is reset
  };

  const onHandlePreviousClick = () => {
    resetZoom();
    handlePrevious();
  };
  const onHandleNextClick = () => {
    resetZoom();
    handleNext();
  };

  return (
    <>
      <div className="image-viewer-left no-select ">
        <div
          className={`navigate-button left ${
            !currentIndex > 0 ? "disabled" : ""
          }`}
          onClick={currentIndex > 0 ? onHandlePreviousClick : null}
        >
          <i className="material-icons">chevron_left</i>
        </div>
        <div
          className="image-container"
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          <img
            src={`${API_BASE_URL}/api/images/image/${currentFile?.name}/${currentFile?.org_id}`}
            alt={currentFile.name}
            className="image-view"
            draggable={false}
            style={{
              transform: `scale(${zoomLevel})`,
              cursor: cursor,
              transition: "transform 0.2s ease-out",
              position: "relative",
              top: position.y,
              left: position.x,
            }}
            onMouseDown={handleMouseDown}
          />
        </div>
        <div
          className={`navigate-button right ${
            currentIndex >= files.length - 1 ? "disabled" : ""
          }`}
          onClick={currentIndex < files.length - 1 ? onHandleNextClick : null}
        >
          <i className="material-icons">chevron_right</i>
        </div>
        <div style={{ position: "fixed", bottom: 0 }}>
          <ImageOptions
            file={currentFile}
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            resetZoom={resetZoom}
          />
        </div>
      </div>
    </>
  );
};

export default ImageViewerPanel;
