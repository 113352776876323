import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
} from "reactstrap";
import downloadDOCX from "./download/downloadDocx";
import downloadPDF from "./download/downloadPdf";

//  TODO :- change  UI
const styles = {
  downloadSection: {
    width: "100%",
    padding: "10px",
  },
  button: {
    width: "70%",
    marginBottom: "15px",
  },
  progressContainer: {
    width: "100%",
    marginBottom: "15px",
  },
  customProgress: {
    height: "20px",
    backgroundColor: "#e9ecef",
    boxShadow: "inset 0 1px 2px rgba(0,0,0,.1)",
    borderRadius: "5px",
  },
  progressText: {
    textAlign: "center",
    position: "relative",
    top: "-20px",
    color: "#000",
    zIndex: 1,
  },
  footerText: {
    marginTop: "10px",
    padding: "5px",
    backgroundColor: "#f8f9fa",
    borderRadius: "4px",
  },
};

const ReaderSettingsPanel = ({
  showOwner,
  setShowOwner,
  showTags,
  setShowTags,
  showNote,
  setShowNote,
  pageRef,
  files,
}) => {
  const [fileType, setFileType] = useState("pdf");
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [showIndex, setShowIndex] = useState(true);
  const [footerText, setFooterText] = useState("");
  const [showFooterModal, setShowFooterModal] = useState(false);

  const handleDownload = async () => {
    setIsLoading(true);
    setShowProgress(true);
    setProgress(0);

    try {
      if (fileType === "pdf") {
        await downloadPDF(pageRef, files, showIndex, footerText, setProgress);
      } else {
        await downloadDOCX(pageRef, files, showIndex, footerText, setProgress);
      }
    } finally {
      setTimeout(() => {
        setIsLoading(false);
        setShowProgress(false);
        setProgress(0);
      }, 1000);
    }
  };

  const toggleFooterModal = () => {
    setShowFooterModal(!showFooterModal);
  };

  const handleFooterSubmit = () => {
    toggleFooterModal();
  };

  const getTextColor = (progressValue) => {
    return progressValue > 60 ? "#fff" : "#000";
  };

  return (
    <>
      <div className="reader-mode-settings-container no-select">
        <div className="rm-settings-header">Display Settings</div>

        <div className="rm-settings-controls-container">
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showOwner}
              onChange={() => setShowOwner(!showOwner)}
            />
            <div className="switch-label">File Owner</div>
          </FormGroup>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showTags}
              onChange={() => setShowTags(!showTags)}
            />
            <div className="switch-label">Tags</div>
          </FormGroup>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showNote}
              onChange={() => setShowNote(!showNote)}
            />
            <div className="switch-label">Note</div>
          </FormGroup>
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              checked={showIndex}
              onChange={() => setShowIndex(!showIndex)}
            />
            <div className="switch-label">Index</div>
          </FormGroup>
        </div>

        <div className="rm-settings-file-type">
          <FormGroup check>
            <Input
              type="radio"
              name="fileType"
              value="pdf"
              checked={fileType === "pdf"}
              onChange={() => setFileType("pdf")}
            />
            <Label check>PDF</Label>
          </FormGroup>
          <FormGroup check>
            <Input
              type="radio"
              name="fileType"
              value="docx"
              checked={fileType === "docx"}
              onChange={() => setFileType("docx")}
            />
            <Label check>DOCX</Label>
          </FormGroup>
        </div>

        <div style={styles.downloadSection}>
          <Button
            color="primary"
            onClick={handleDownload}
            disabled={isLoading}
            style={styles.button}
          >
            {isLoading ? "Downloading..." : "Download"}
          </Button>

          {showProgress && (
            <div style={styles.progressContainer}>
              <Progress
                value={progress}
                style={styles.customProgress}
                barStyle={{
                  background: "linear-gradient(to right, #0066cc, #1a8cff)",
                  transition: "width 0.3s ease-in-out",
                  boxShadow: "0 0 5px rgba(0, 102, 204, 0.5)",
                }}
              />
              <div
                style={{
                  ...styles.progressText,
                  color: getTextColor(progress),
                  transition: "color 0.3s ease",
                }}
              >
                {progress}%
              </div>
            </div>
          )}

          {showIndex && (
            <Button
              color="secondary"
              onClick={toggleFooterModal}
              style={styles.button}
            >
              Set Footer
            </Button>
          )}

          {footerText && (
            <div style={styles.footerText}>Footer: {footerText}</div>
          )}
        </div>
      </div>

      <Modal isOpen={showFooterModal} toggle={toggleFooterModal}>
        <ModalHeader toggle={toggleFooterModal}>Set Footer</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            placeholder="Enter footer text"
            value={footerText}
            onChange={(e) => setFooterText(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleFooterSubmit}>
            Set
          </Button>
          <Button color="secondary" onClick={toggleFooterModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReaderSettingsPanel;
