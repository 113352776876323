// apiService.js

import { createHeaders, handleErrors, parseJSON } from "./api-middleware";
import { API_BASE_URL } from "./base-url";

// Function to perform a POST request for login
export const fetchFoldersApi = async (orgId, successHandler, errorHandler) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folders/${orgId}`, {
      method: "GET",
      headers: createHeaders(), // Use the headers function
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const fetchFoldersById = async (
  orgId,
  folderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/${orgId}/${folderId}/content`,
      {
        method: "GET",
        // headers: createHeaders(), // Use the headers function
      }
    );

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    console.log(data);
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const fetchFolderDetailsById = async (
  orgId,
  folderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/${orgId}/${folderId}`,
      {
        method: "GET",
        // headers: createHeaders(), // Use the headers function
      }
    );

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const createFolder = async (folder, successHandler, errorHandler) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folders`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify(folder), // Use the headers function
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const updateFolderName = async (
  folderId,
  userId,
  folderName,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/folders/${folderId}`, {
      method: "PUT",
      headers: createHeaders(),
      body: JSON.stringify({
        user_id: userId,
        folder_name: folderName,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler();
    }
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchBreadcrumbForFolder = async (
  folderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/folder_hierarchy/${folderId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data) {
      if (successHandler) {
        successHandler();
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const moveFolder = async (
  folderId,
  newParentFolderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/move/${folderId}`,
      {
        method: "PUT",
        headers: createHeaders(),
        body: JSON.stringify({
          new_parent_folder_id: newParentFolderId,
        }),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const fetchFolderHierarchy = async (orgId, storageType) => {
  // Currently by default fetching shared storage hierarchy
  // ToDo: Fetch hierarchy based on storageType = my_storage or shared_storage
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/full_hierarchy/${orgId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response);
    const data = await parseJSON(response);
    return data;
  } catch (error) {
    console.error("Error fetching folder hierarchy:", error);
    throw error;
  }
};

export const moveFolderToTrashService = async (
  folderId,
  userId,
  orgId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/move_to_bin/${folderId}`,
      {
        method: "PUT",
        headers: createHeaders(),
        body: JSON.stringify({
          user_id: userId,
          org_id: orgId,
        }),
      }
    );

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);
    if (data && successHandler) {
      successHandler(data);
    }
    return data;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};

export const fetchFolderForDownload = async (
  orgId,
  folderId,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/api/folders/download/${orgId}/${folderId}`,
      {
        method: "GET",
        headers: createHeaders(),
      }
    );

    await handleErrors(response, errorHandler);

    const blob = await response.blob();

    if (blob && successHandler) {
      successHandler(blob);
    }
    return blob;
  } catch (error) {
    if (errorHandler) {
      errorHandler(error);
    } else {
      throw error;
    }
  }
};
