import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  FaArrowRight,
  FaSquare,
  FaCircle,
  FaFont,
  FaSlash,
  FaHighlighter,
  FaEraser,
  FaTrash,
  FaBroom,
  FaUndo,
  FaRedo,
  FaSave,
  FaPalette,
  FaMousePointer,
  FaPencilAlt,
  FaRuler,
} from "react-icons/fa";
import { MdBlurOn } from "react-icons/md";
import "../../../../assets/scss/image-editor.scss";
import Arrow from "./shapes/Arrow";
import Circle from "./shapes/Circle";
import Rectangle from "./shapes/Rectangle";
import Line from "./shapes/Line";
import Text from "./shapes/Text";
import Highlighter from "./shapes/Highlighter";
import ColorPalette from "./features/color-palette";
import ZoomOption from "./features/ZoomOption";
import Eraser from "./shapes/Eraser";
import Pencil from "./shapes/Pencil";
import SelectionManager from "./features/SelectionManager";
import RotateFlipOptions from "./features/RotateFlipOptions";
import CursorOverlay from "./features/CursorOverlay";
import Pixelate from "./shapes/Pixelate";
import ConfirmationModal from "../common/confirmation-modal";
import { API_BASE_URL } from "../../redux/services/base-url";

const ImageEditor = ({ isOpen, toggle, imageUrl, currentFile }) => {
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);
  const [zoom, setZoom] = useState(100);
  const [selectedColor, setSelectedColor] = useState("#e74c3c");
  const [hasChanges, setHasChanges] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [shapes, setShapes] = useState([]);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentShape, setCurrentShape] = useState(null);
  const [selectedShape, setSelectedShape] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [flipHorizontal, setFlipHorizontal] = useState(false);
  const [flipVertical, setFlipVertical] = useState(false);
  const [isCursorOnCanvas, setIsCursorOnCanvas] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartPos, setDragStartPos] = useState({ x: 0, y: 0 });
  const [pixelateSize, setPixelateSize] = useState(10);
  const [currentWidth, setCurrentWidth] = useState(2);
  const [openPanel, setOpenPanel] = useState(null);
  const [originalImageDimensions, setOriginalImageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const canvasRef = useRef(null);
  const imgRef = useRef(null);
  const containerRef = useRef(null);
  const imageBoundsRef = useRef({ x: 0, y: 0, width: 0, height: 0 });
  const selectionManager = useRef(new SelectionManager());

  useEffect(() => {
    if (isOpen && imageUrl) {
      setImageLoaded(false);
      setError(null);
      setShapes([]);
      setUndoStack([]);
      setRedoStack([]);

      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        imgRef.current = img;
        setImageLoaded(true);
      };
      img.onerror = () => {
        setError("Failed to load image. Please try again.");
      };
      img.src = imageUrl;
    }
  }, [isOpen, imageUrl]);

  useEffect(() => {
    if (
      imageLoaded &&
      canvasRef.current &&
      imgRef.current &&
      containerRef.current
    ) {
      const canvas = canvasRef.current;
      const container = containerRef.current;
      const img = imgRef.current;

      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;

      const scale =
        Math.min(
          canvas.width / img.naturalWidth,
          canvas.height / img.naturalHeight
        ) *
        (zoom / 100);

      const scaledWidth = img.naturalWidth * scale;
      const scaledHeight = img.naturalHeight * scale;

      const x = (canvas.width - scaledWidth) / 2;
      const y = (canvas.height - scaledHeight) / 2;

      imageBoundsRef.current = {
        x,
        y,
        width: scaledWidth,
        height: scaledHeight,
      };

      redrawCanvas();
    }
  }, [imageLoaded, zoom, shapes]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    setOriginalImageDimensions({
      width: imgRef.current.naturalWidth,
      height: imgRef.current.naturalHeight,
    });
  };

  const handleImageError = () => {
    setError("Failed to load image. Please try again.");
  };

  const getMousePosition = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    let x = (e.clientX - rect.left) * scaleX;
    let y = (e.clientY - rect.top) * scaleY;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    x = (x - centerX) / (zoom / 100) + centerX;
    y = (y - centerY) / (zoom / 100) + centerY;

    x -= centerX;
    y -= centerY;

    const rad = (-rotation * Math.PI) / 180;
    const rotatedX = x * Math.cos(rad) - y * Math.sin(rad);
    const rotatedY = x * Math.sin(rad) + y * Math.cos(rad);

    const flippedX = flipHorizontal ? -rotatedX : rotatedX;
    const flippedY = flipVertical ? -rotatedY : rotatedY;

    x = flippedX + centerX;
    y = flippedY + centerY;

    return { x, y };
  };

  const isInsideImage = (pos) => {
    const { x, y, width, height } = imageBoundsRef.current;
    return (
      pos.x >= x && pos.x <= x + width && pos.y >= y && pos.y <= y + height
    );
  };

  const handleMouseDown = (e) => {
    const pos = getMousePosition(e);
    if (!isInsideImage(pos)) return;

    if (selectedTool === "select") {
      const selectedShape = selectionManager.current.selectShape(
        shapes,
        pos.x,
        pos.y
      );
      setSelectedShape(selectedShape);
      if (selectedShape) {
        setIsDragging(true);
        setDragStartPos(pos);
      }
      redrawCanvas();
      return;
    }

    selectionManager.current.clearSelection();
    setSelectedShape(null);

    setIsDrawing(true);
    setStartPos(pos);

    let shape;
    switch (selectedTool) {
      case "arrow":
        shape = new Arrow(
          pos.x,
          pos.y,
          pos.x,
          pos.y,
          selectedColor,
          currentWidth
        );
        break;
      case "rectangle":
        shape = new Rectangle(pos.x, pos.y, 0, 0, selectedColor, currentWidth);
        break;
      case "circle":
        shape = new Circle(
          pos.x,
          pos.y,
          0,
          selectedColor,
          currentWidth,
          imageBoundsRef.current
        );
        break;
      case "line":
        shape = new Line(
          pos.x,
          pos.y,
          pos.x,
          pos.y,
          selectedColor,
          currentWidth
        );
        break;
      case "highlight":
        shape = new Highlighter(pos.x, pos.y, selectedColor, currentWidth);
        break;
      case "pencil":
        shape = new Pencil(pos.x, pos.y, selectedColor, currentWidth);
        break;
      case "eraser":
        shape = new Eraser(15);
        shape.setPosition(pos.x, pos.y);
        break;
      case "pixelate":
        shape = new Pixelate(pos.x, pos.y, pixelateSize);
        break;
      case "text":
        const text = prompt("Enter text:");
        if (text) {
          shape = new Text(
            pos.x,
            pos.y,
            text,
            selectedColor,
            currentWidth * 5 * 1.2
          );
          addShape(shape);
        }
        break;
    }
    setCurrentShape(shape);
  };

  const handleMouseMove = (e) => {
    const pos = getMousePosition(e);
    if (!isInsideImage(pos)) return;

    if (isDragging && selectedShape) {
      const dx = pos.x - dragStartPos.x;
      const dy = pos.y - dragStartPos.y;
      selectedShape.move(dx, dy);
      setDragStartPos(pos);
      redrawCanvas();
      return;
    }

    if (isDrawing && currentShape) {
      if (selectedTool === "eraser") {
        currentShape.setPosition(pos.x, pos.y);
        const newShapes = currentShape.erase(shapes);
        setShapes(newShapes);
      } else if (selectedTool === "circle") {
        const radius = Math.sqrt(
          Math.pow(pos.x - startPos.x, 2) + Math.pow(pos.y - startPos.y, 2)
        );
        currentShape.updateRadius(radius);
      } else if (selectedTool === "rectangle") {
        currentShape.width = pos.x - startPos.x;
        currentShape.height = pos.y - startPos.y;
      } else if (["highlight", "pencil", "pixelate"].includes(selectedTool)) {
        currentShape.addPoint(pos.x, pos.y);
      } else if (
        currentShape.endX !== undefined &&
        currentShape.endY !== undefined
      ) {
        currentShape.endX = pos.x;
        currentShape.endY = pos.y;
      }

      redrawCanvas();
    }
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false);
      return;
    }
    if (isDrawing && currentShape && selectedTool !== "eraser") {
      addShape(currentShape);
    }
    setIsDrawing(false);
    setCurrentShape(null);
  };

  const addShape = (shape) => {
    setShapes((prevShapes) => [...prevShapes, shape]);
    setUndoStack((prevStack) => [...prevStack, { shapes: [...shapes] }]);
    setRedoStack([]);
    setHasChanges(true);
  };

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmation(true);
    } else {
      toggle();
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmation(false);
    setHasChanges(false);
    toggle();
  };

  const handleCancelClose = () => {
    setShowConfirmation(false);
  };

  const redrawCanvas = (
    currentRotation = rotation,
    currentFlipH = flipHorizontal,
    currentFlipV = flipVertical
  ) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = imgRef.current;
    const { x, y, width, height } = imageBoundsRef.current;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.save();

    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((currentRotation * Math.PI) / 180);
    ctx.scale(currentFlipH ? -1 : 1, currentFlipV ? -1 : 1);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    ctx.drawImage(img, x, y, width, height);

    ctx.restore();
    ctx.save();

    const scale = zoom / 100;
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate((currentRotation * Math.PI) / 180);
    ctx.scale((currentFlipH ? -1 : 1) * scale, (currentFlipV ? -1 : 1) * scale);
    ctx.translate(-canvas.width / 2, -canvas.height / 2);

    if (Array.isArray(shapes)) {
      shapes.forEach((shape) => {
        ctx.save();
        const transformScale = Math.abs(ctx.getTransform().a);
        ctx.lineWidth = shape.lineWidth / transformScale;
        shape.draw(ctx);
        ctx.restore();
      });
    }

    if (currentShape) {
      ctx.save();
      const transformScale = Math.abs(ctx.getTransform().a);
      ctx.lineWidth = currentShape.lineWidth / transformScale;
      currentShape.draw(ctx);
      ctx.restore();
    }

    ctx.restore();
  };

  const handleFlipHorizontal = () => {
    const newFlipHorizontal = !flipHorizontal;
    setFlipHorizontal(newFlipHorizontal);
    addToHistory();
    redrawCanvas(rotation, newFlipHorizontal, flipVertical);
  };

  const handleFlipVertical = () => {
    const newFlipVertical = !flipVertical;
    setFlipVertical(newFlipVertical);
    addToHistory();
    redrawCanvas(rotation, flipHorizontal, newFlipVertical);
  };

  const addToHistory = () => {
    setUndoStack([...undoStack, { shapes }]);
    setRedoStack([]);
  };

  const handleUndo = () => {
    if (undoStack.length === 0) return;
    const previousState = undoStack[undoStack.length - 1];
    setRedoStack([...redoStack, { shapes: [...shapes] }]);
    setShapes(previousState.shapes || []);
    setUndoStack(undoStack.slice(0, -1));
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;
    const nextState = redoStack[redoStack.length - 1];
    setUndoStack([...undoStack, { shapes: [...shapes] }]);
    setShapes(nextState.shapes || []);
    setRedoStack(redoStack.slice(0, -1));
  };

  const handleClearAll = () => {
    setUndoStack([...undoStack, shapes]);
    setShapes([]);
    setRedoStack([]);
  };

  const handleSave = async () => {
    const canvas = canvasRef.current;
    if (!canvas || !currentFile) return;

    const ctx = canvas.getContext("2d");
    const { x, y, width, height } = imageBoundsRef.current;

    const tempCanvas = document.createElement("canvas");
    tempCanvas.width = width;
    tempCanvas.height = height;
    const tempCtx = tempCanvas.getContext("2d");

    tempCtx.drawImage(canvas, x, y, width, height, 0, 0, width, height);

    tempCanvas.toBlob(async (blob) => {
      if (!blob) return;

      const formData = new FormData();
      formData.append("image", blob, currentFile.name);
      formData.append("imageId", currentFile._id);
      formData.append("orgId", currentFile.org_id);

      try {
        const response = await fetch(
          `${API_BASE_URL}/api/images/update-edited`,
          {
            method: "PUT",
            body: formData,
          }
        );

        if (response.ok) {
          alert("Image saved successfully!");
          setHasChanges(false);
        } else {
          console.error("Failed to update image");
          alert("Failed to save image. Please try again.");
        }
      } catch (error) {
        console.error("Error updating image:", error);
        alert("An error occurred while saving the image. Please try again.");
      }
    }, "image/png");
  };

  const handleDeleteSelected = () => {
    const newShapes = selectionManager.current.deleteSelectedShape(shapes);
    if (newShapes !== shapes) {
      setShapes(newShapes);
      setSelectedShape(null);
      setUndoStack([...undoStack, shapes]);
      setRedoStack([]);
      redrawCanvas();
    }
  };

  const handleMouseEnter = () => {
    setIsCursorOnCanvas(true);
  };

  const handleMouseLeave = () => {
    setIsCursorOnCanvas(false);
  };

  const getContrastColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);

    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5 ? "#000000" : "#ffffff";
  };

  const WidthSelector = ({ onSelect, currentWidth }) => {
    const widthOptions = [
      { display: "1", value: 2 },
      { display: "2", value: 4 },
      { display: "3", value: 8 },
      { display: "4", value: 12 },
    ];

    return (
      <div className="width-selector">
        {widthOptions.map((option) => (
          <button
            key={option.display}
            className={`width-option ${
              currentWidth === option.value ? "selected" : ""
            }`}
            onClick={() => onSelect(option.value)}
          >
            <div
              className="width-preview"
              style={{
                height: `${option.value}px`,
                backgroundColor:
                  currentWidth === option.value ? "#3b82f6" : "#64748b",
              }}
            ></div>
            <span>{option.display}px</span>
          </button>
        ))}
      </div>
    );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={handleClose}
        className="image-editor-modal"
        fullscreen
      >
        <ModalHeader toggle={handleClose} close={<></>}>
          <div className="d-flex justify-content-between align-items-center">
            Edit Image
            <div>
              <Button color="primary" size="sm" onClick={handleSave}>
                Save & Close
              </Button>
              <Button
                color="secondary"
                size="sm"
                className="ms-2"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="">
            <div className="top-toolbar">
              <div className="toolbar-group">
                <div className="history-controls">
                  <button
                    className="tool-button"
                    title="Undo"
                    onClick={handleUndo}
                  >
                    <FaUndo />
                  </button>
                  <button
                    className="tool-button"
                    title="Redo"
                    onClick={handleRedo}
                  >
                    <FaRedo />
                  </button>
                </div>
                <ZoomOption zoom={zoom} setZoom={setZoom} />
              </div>

              <div className="toolbar-group">
                <div style={{ position: "relative" }}>
                  <button
                    className={`tool-button color-indicator ${
                      openPanel === "color" ? "selected" : ""
                    }`}
                    onClick={() => {
                      if (openPanel === "color") {
                        setOpenPanel(null);
                      } else {
                        setOpenPanel("color");
                      }
                    }}
                    title="Color Palette"
                    style={{ backgroundColor: selectedColor }}
                  >
                    <FaPalette
                      style={{ color: getContrastColor(selectedColor) }}
                    />
                  </button>
                  {openPanel === "color" && (
                    <div
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <ColorPalette
                        color={selectedColor}
                        onChange={(color) => {
                          setSelectedColor(color.hex);
                        }}
                        onColorSelect={(color) => {
                          setSelectedColor(color);
                          setOpenPanel(null);
                        }}
                      />
                    </div>
                  )}
                </div>

                <div style={{ position: "relative" }}>
                  <button
                    className={`tool-button ${
                      openPanel === "width" ? "selected" : ""
                    }`}
                    onClick={() => {
                      if (openPanel === "width") {
                        setOpenPanel(null);
                      } else {
                        setOpenPanel("width");
                      }
                    }}
                    title="Set Width"
                  >
                    <FaRuler />
                  </button>
                  {openPanel === "width" && (
                    <WidthSelector
                      onSelect={(width) => {
                        setCurrentWidth(width);
                        setOpenPanel(null);
                      }}
                      currentWidth={currentWidth}
                    />
                  )}
                </div>
                <RotateFlipOptions
                  onFlipHorizontal={handleFlipHorizontal}
                  onFlipVertical={handleFlipVertical}
                />
              </div>

              <div className="toolbar-group"></div>
            </div>

            <div className="editor-container">
              <div className="left-toolbar">
                <div className="tool-section">
                  <button
                    className={`tool-button ${
                      selectedTool === "arrow" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("arrow")}
                    title="Arrow"
                  >
                    <FaArrowRight />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "rectangle" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("rectangle")}
                    title="Rectangle"
                  >
                    <FaSquare />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "circle" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("circle")}
                    title="Circle"
                  >
                    <FaCircle />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "line" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("line")}
                    title="Line"
                  >
                    <FaSlash />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "text" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("text")}
                    title="Text"
                  >
                    <FaFont />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "highlight" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("highlight")}
                    title="Highlight"
                  >
                    <FaHighlighter />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "pencil" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("pencil")}
                    title="Pencil"
                  >
                    <FaPencilAlt />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "pixelate" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("pixelate")}
                    title="Pixelate"
                  >
                    <MdBlurOn />
                  </button>
                </div>

                <div className="tool-section">
                  <button
                    className={`tool-button ${
                      selectedTool === "eraser" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("eraser")}
                    title="Eraser"
                  >
                    <FaEraser />
                  </button>
                  <button
                    className="tool-button"
                    title="Delete Selected"
                    onClick={handleDeleteSelected}
                    disabled={!selectionManager.current.getSelectedShape()}
                  >
                    <FaTrash />
                  </button>
                  <button
                    className="tool-button"
                    title="Clear All"
                    onClick={handleClearAll}
                  >
                    <FaBroom />
                  </button>
                  <button
                    className={`tool-button ${
                      selectedTool === "select" ? "selected" : ""
                    }`}
                    onClick={() => setSelectedTool("select")}
                    title="Select"
                  >
                    <FaMousePointer />
                  </button>
                </div>
              </div>

              <div className="canvas-container" ref={containerRef}>
                {error ? (
                  <div className="error-message">{error}</div>
                ) : (
                  <>
                    <img
                      ref={imgRef}
                      src={imageUrl}
                      alt="Editable image"
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                      style={{ display: "none" }}
                    />
                    <canvas
                      ref={canvasRef}
                      className="editor-canvas"
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={(e) => {
                        handleMouseUp(e);
                        handleMouseLeave(e);
                      }}
                    />
                    {!imageLoaded && (
                      <div className="loading-message">Loading image...</div>
                    )}
                  </>
                )}
              </div>
            </div>
            <CursorOverlay
              selectedTool={selectedTool}
              isVisible={isCursorOnCanvas}
            />
          </div>
        </ModalBody>
      </Modal>
      <ConfirmationModal
        isOpen={showConfirmation}
        toggle={handleCancelClose}
        title="Unsaved Changes"
        description="You have unsaved changes. Are you sure you want to close the editor?"
        confirmButtonText="Discard Changes"
        cancelButtonText="Continue Editing"
        onConfirm={handleConfirmClose}
        isDanger={true}
      />
    </>
  );
};
export default ImageEditor;
