import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { moveFileToTrashService } from "../services/file-service";
import { moveFolderToTrashService } from "../services/folder-service";
import {
    deleteFolderForeverService,
    deleteImageForeverService,
    emptyTrashService,
    fetchTrashContents,
    restoreFromTrashService,
} from "../services/trash-service";

const initialState = {
  folders: [],
  files: [],
};

export const fetchTrashContentsAction = createAsyncThunk(
  "folder/fetchTrashContents",
  async ({ orgId }, { dispatch }) => {
    const data = await fetchTrashContents(orgId);
    return data;
  }
);

export const moveFolderToTrashAction = createAsyncThunk(
  "folder/moveFolderToTrash",
  async (folder, { dispatch }) => {
    const data = await moveFolderToTrashService(
      folder._id,
      folder.user_id,
      folder.org_id
    );
    return { data: data, folder: folder };
  }
);

export const moveFileToTrashAction = createAsyncThunk(
  "folder/moveFileToTrash",
  async (file, { dispatch }) => {
    const data = await moveFileToTrashService(
      file._id,
      file.created_by,
      file.org_id,
      file.lang
    );
    return { data: data, file: file };
  }
);

export const restoreFromTrashAction = createAsyncThunk(
  "folder/restoreFromTrash",
  async ({ item, itemType }, { dispatch }) => {
    const data = await restoreFromTrashService(item.org_id, item._id, itemType);
    return { data: data, item: item, itemType: itemType };
  }
);

export const deleteFileForeverAction = createAsyncThunk(
  "folder/deleteFileForever",
  async ({ file, successHandler }, { dispatch }) => {
    const data = await deleteImageForeverService(
      file._id,
      file.language || file.lang || "marathi",
      successHandler
    );
    return { data: data, file: file };
  }
);

export const deleteFolderForeverAction = createAsyncThunk(
  "folder/deleteFolderForever",
  async ({ folder, successHandler }, { dispatch }) => {
    const data = await deleteFolderForeverService(
      folder._id,
      folder.user_id,
      successHandler
    );
    return { data: data, folder: folder };
  }
);

export const emptyTrashAction = createAsyncThunk(
  "folder/emptyTrash",
  async ({ orgId, successHandler }, { dispatch }) => {
    const data = await emptyTrashService(orgId, successHandler);
    return data;
  }
);

const trashSlice = createSlice({
  name: "trash",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrashContentsAction.fulfilled, (state, action) => {
        state.folders = action.payload.trashed_folders;
        state.files = action.payload.trashed_images;
      })
      .addCase(moveFolderToTrashAction.fulfilled, (state, action) => {
        // This is not actually required here as this action will never happen inside trash.
        // So this is also handled in active-folder-slice as well as folders-slice
        state.folders = [...state.folders, action.payload.folder];
      })
      .addCase(moveFileToTrashAction.fulfilled, (state, action) => {
        // This is not actually required here as this action will never happen inside trash.
        // So this is also handled in active-folder-slice as well as folders-slice
        state.files = [...state.files, action.payload.file];
      })
      .addCase(restoreFromTrashAction.fulfilled, (state, action) => {
        if (action.payload.itemType === "folder") {
          state.folders = state.folders.filter(
            (folder) => folder._id !== action.payload.item._id
          );
        } else if (action.payload.itemType === "image") {
          state.files = state.files.filter(
            (file) => file._id !== action.payload.item._id
          );
        }
      })
      .addCase(deleteFileForeverAction.fulfilled, (state, action) => {
        state.files = state.files.filter(
          (file) => file._id !== action.payload.file._id
        );
      })
      .addCase(deleteFolderForeverAction.fulfilled, (state, action) => {
        state.folders = state.folders.filter(
          (folder) => folder._id !== action.payload.folder._id
        );
      })
      .addCase(emptyTrashAction.fulfilled, (state, action) => {
        state.folders = [];
        state.files = [];
      });
  },
});

export const {} = trashSlice.actions;

export const getTrashFiles = (state) => state.trash.files;
export const getTrashFolders = (state) => state.trash.folders;

export default trashSlice.reducer;
