import { useState } from "react";
import { getTransliterateSuggestions } from "react-transliterate";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

const SuggestionSearchInput = ({ handleSearch, setSearchText, searchText }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const toggle = () => setIsOpen(!isOpen);

  const handleInputChange = async (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value) {
      const data = await getTransliterateSuggestions(
        value, // word to fetch suggestions for
        {
          numOptions: 5, // number of suggestions to fetch
          showCurrentWordAsLastSuggestion: true, // add the word as the last suggestion
          lang: "mr", // target language
        }
      );
      setSuggestions(data);
      toggle();
    } else {
      setSuggestions([]);
      setIsOpen(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchText(suggestion);
    setSuggestions([]);
    setIsOpen(false);
  };

  return (
    <div className="autocomplete-wrapper">
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle caret>
          <Input
            type="text"
            className="search-input"
            value={searchText}
            placeholder="Search files, folders"
            onChange={handleInputChange}
            onKeyDown={handleSearch}
          />
        </DropdownToggle>
        <DropdownMenu>
          {suggestions.map((suggestion, index) => (
            <DropdownItem
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {suggestion}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default SuggestionSearchInput;
