import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { updateOrganization } from "../redux/slices/organization-slice";
import { updateUser } from "../redux/slices/user-slice";
import routes from "./routes/routes";
import { setActiveFolderId } from "../redux/slices/active-folder-slice";

const StorageApp = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector(state=>state.user)
  const organization = useSelector(state=>state.organization)

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      return (
        <Route
          path={prop.layout + prop.path}
          Component={prop.component}
          key={key}
        />
      );
    });
  };

  useEffect(() => {
    // Parse the search string to get query parameters
    const searchParams = new URLSearchParams(location.search);

    // Check if 'folderId' query parameter exists
    if (!searchParams.has("folderId")) {
      // If no active folder found, reset the active folder id
      setTimeout(() => {
        dispatch(setActiveFolderId(null));
      }, 0); // Delay the reset to prevent flickering empty state
    }
  }, [location]);

  useEffect(() => {
    let userProps = props.user;
    let organizationProps = props.organization;
     
    if (userProps && userProps.id != "" ) {
    
      if((!user ||user.id !=userProps.id)){
        dispatch(updateUser(userProps));
        
      }
      if (organizationProps && organizationProps.id && organizationProps.id != ""&&(!organization||organization.id!=organizationProps.id)) {
        console.log("here dk")
        // alert(JSON.stringify(organization))
        dispatch(updateOrganization(organizationProps));
      } else {
        // navigate("/auth");
      }
    
    } else {
      navigate("/auth");
    }
  }, [props.user, props.organization,user,organization]);

  

  return (
    <div className="storage-wrapper">
      <Routes>{getRoutes(routes)}
      <Route path="*" element={<Navigate to="/app/home" replace />} />
      </Routes>
    </div>
  );
};

export default StorageApp;
