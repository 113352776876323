import React from "react";
import { Col, Row } from "reactstrap";

const SectionHeader = ({ title }) => {
  return (
    <div className="section-header">
      <Row>
        <Col>
          <div className="title">{title}</div>
        </Col>
      </Row>
    </div>
  );
};

export default SectionHeader;
