import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveFolderId,
  showUploadSection,
} from "../../redux/slices/active-folder-slice";
import ContextMenu from "../common/context-menu";
import NewFolderModal from "../folders/modals/new-folder-modal";

const NewItemButton = () => {
  const dispatch = useDispatch();

  const [newFolderModal, setNewFolderModal] = useState(false);

  const activeFolderId = useSelector(getActiveFolderId);

  const toggleNewFolderModal = () =>
    setNewFolderModal((prevState) => !prevState);

  const handleNewFolderClick = () => {
    toggleNewFolderModal();
  };

  const handleFileUploadClick = () => {
    dispatch(showUploadSection());
  };

  const menuItems = [
    {
      label: "New Folder",
      icon: "create_new_folder",
      onClick: handleNewFolderClick,
    },
    // {
    //   divider: true, // This will add a divider
    // },
    ...(activeFolderId
      ? [
          {
            label: "File Upload",
            icon: "upload",
            onClick: handleFileUploadClick,
          },
        ]
      : []),
  ];

  return (
    <>
      <ContextMenu menuText="New" menuItems={menuItems} />
      <NewFolderModal isOpen={newFolderModal} toggle={toggleNewFolderModal} />
    </>
  );
};

export default NewItemButton;
