import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import TooltipCustom from "../common/tooltip-custom";
import routes from "../../layout/routes/routes";
import SuggestionSearchInput from "./suggestion-search-input";

const SearchBar = () => {
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("q");

  const searchRoute = routes.find((route) => route.id === "SEARCH");

  useEffect(() => {
    setSearchText(query ? query : "");
  }, [query]);

  const handleClear = () => {
    setSearchText("");
    queryParams.delete("q"); // Clear the query text from query parameters
    queryParams.delete("type"); // Clear the type filter from query parameters
    queryParams.delete("tags"); // Clear the tags filter from query parameters
    queryParams.delete("after"); // Clear the after date filter from query parameters
    queryParams.delete("before"); // Clear the before date filter from query parameters
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleSearch = (e) => {
    if (e.key === "Enter" && searchText.trim() !== "") {
      queryParams.delete("folderId"); // Removing folderId from URL in case search is executed inside a folder. We may revert this in future.
      queryParams.set("q", searchText.trim());
      navigate(
        `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
      );
    }
  };

  return (
    <div className="search-bar-container">
      <i className="material-icons" style={{ color: "darkgray" }}>
        search
      </i>
     <SuggestionSearchInput setSearchText={setSearchText} searchText={searchText} handleSearch={handleSearch}/>
      <div className="d-flex">
        {searchText && (
          <i className="material-icons icon-button" onClick={handleClear}>
            clear
          </i>
        )}
        <i id="advanced-search-button" className="material-icons icon-button">
          tune
        </i>
      </div>
      <TooltipCustom text="Advanced Search" target={"advanced-search-button"} />
    </div>
  );
};

export default SearchBar;
