// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateOrganization } from "./organization-slice"; // Import the action to update the organization
import { loginUser, changePassword } from "../services/auth-service";

const initialState = localStorage.getItem("@antridge-organizaion/user")
  ? JSON.parse(localStorage.getItem("@antridge-organizaion/user"))
  : {};

export const login = createAsyncThunk(
  "user/login",
  async ({ credentials, successHandler, errorHandler }, { dispatch }) => {
    const data = await loginUser(credentials, successHandler, errorHandler);
    dispatch(updateOrganization(data.organization)); // Assuming organization data is included in the response
    return data.user; // Return user data for updating the state
  }
);

export const changeUserPassword = createAsyncThunk(
  "user/changePassword",
  async ({ credentials, successHandler, errorHandler }) => {
    return await changePassword(credentials, successHandler, errorHandler);
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: (state, action) => {
      return {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Update the state with user data
        state = {
          ...state,
          ...action.payload,
        };
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(changeUserPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changeUserPassword.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetUser } = userSlice.actions;

export default userSlice.reducer;
