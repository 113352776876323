import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../components/common/empty-state";
import FileThumbnails from "../components/folders/file-thumbnails";
import FolderThumbnails from "../components/folders/folder-thumbnails";
import StorageNavbar from "../components/storage-navbar/storage-navbar";
import {
  getAllFavoritesAction,
  getFavorites,
} from "../redux/slices/favorites-slice";

const FavouritesView = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const organization = useSelector((state) => state.organization);
  const user = useSelector((state) => state.user);
  const favorites = useSelector(getFavorites);

  useEffect(() => {
    if (organization && organization.id && user && user.id) {
      setIsLoading(true);
      dispatch(
        getAllFavoritesAction({ orgId: organization.id, userId: user.id })
      )
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          // ToDo: Error handling
        });
    }
  }, [organization.id, user.id, dispatch]);

  return (
    <>
      <StorageNavbar />
      <div className="storage-content">
        {!isLoading ? (
          (favorites?.folders && favorites.folders.length > 0) ||
          (favorites?.files && favorites.files.length > 0) ? (
            <>
              <FolderThumbnails folders={favorites.folders || []} />
              <FileThumbnails files={favorites.files || []} />
            </>
          ) : (
            <EmptyState text="No favourites found." />
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default FavouritesView;
