import { format } from "date-fns";

const downloadItem = (item, name) => {
  // Create a temporary URL from the blob
  const url = URL.createObjectURL(item);

  // Create a temporary anchor element
  const downloadLink = Object.assign(document.createElement("a"), {
    href: url,
    download: name,
    style: "display: none",
  });
  // Append the anchor to the document and trigger a click
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up the URL and the anchor
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url); // Free up memory
};

export const downloadFile = (file, name) => {
  downloadItem(file, name);
};

export const downloadFolder = (folder, name) => {
  // Get current date and time
  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyyMMdd'T'HHmmss'_Proxima'");
  // Append date and time to folder name
  const folderNameWithDate = `${name}-${formattedDate}`;
  downloadItem(folder, folderNameWithDate);
};
