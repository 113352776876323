import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SHORTCUTS } from "../../constants/shortcuts";
import {
  resetShortcutKey,
  setShortcutKey,
} from "../../redux/slices/shortcut-slice";
import ImageDetailsPanel from "./image-details-panel";
import ImageViewerPanel from "./image-viewer-panel";

const ImageViewerModal = ({ isOpen, toggle, files, currentFileIndex }) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(currentFileIndex);
  const [currentFile, setCurrentFile] = useState(files[currentFileIndex]);

  useEffect(() => {
    setCurrentFile(files[currentFileIndex]);
  }, [files]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.shiftKey) {
        if (e.key === "E") {
          dispatch(setShortcutKey(SHORTCUTS.EDIT_TAGS));
        } else if (e.key === "Q") {
          dispatch(setShortcutKey(SHORTCUTS.EDIT_NOTE));
        }
      } else if (e.key === "Escape") {
        // dispatch(setShortcutKey("Escape"));
        // ToDo for @Pankaj
      } else if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
        const activeElement = document.activeElement;
        // Do not do anything when keyboard keys are pressed inside text areas
        if (
          activeElement.tagName === "INPUT" ||
          activeElement.tagName === "TEXTAREA"
        ) {
          return;
        }

        if (e.key === "ArrowRight") {
          handleNext();
        } else if (e.key === "ArrowLeft") {
          handlePrevious();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    setCurrentFile(files[currentIndex]);
    dispatch(resetShortcutKey()); // Reset shortcut when file changed
    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  const handleNext = () => {
    if (currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="image-viewer-modal"
      fullscreen
    >
      <ModalHeader
        className="d-flex justify-content-between align-items-center"
        toggle={toggle}
      >
        {currentFile.alias?.length > 0 ? currentFile.alias : currentFile.name}
      </ModalHeader>
      <ModalBody>
        {/* Left Panel */}
        <ImageViewerPanel
          files={files}
          currentFile={currentFile}
          currentIndex={currentIndex}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
        />
        {/* Right Panel */}
        <ImageDetailsPanel currentFile={currentFile} />
      </ModalBody>
    </Modal>
  );
};

export default ImageViewerModal;
