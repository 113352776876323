import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import FolderContextMenu from "../menu/folder-context-menu";

const FolderThumbnailItem = ({ folder, userId,onClick, onDoubleClick }) => {
  return (
    <>
      <Card
        className="folder-thumbnail-item no-select"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <CardBody className="d-flex align-items-center">
          <i className="material-icons folder-icon">folder</i>
          <CardTitle className="mb-0 folder-name">
            {folder.folder_name}
          </CardTitle>
          <FolderContextMenu folder={folder} userId={userId} onFolderOpen={onDoubleClick} />
        </CardBody>
      </Card>
    </>
  );
};

export default FolderThumbnailItem;
