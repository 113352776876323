import React from "react";

const UploadWindowHeader = ({ isMinimized, handleMinimize, handleClose }) => {
  return (
    <div className="upload-header">
      <span>{isMinimized ? "Upload files" : "Upload files"}</span>
      <div className="upload-controls">
        <i
          class="material-icons icon-button"
          style={{
            fontSize: 24,
            lineHeight: 1,
            verticalAlign: "middle",
            height: "40px",
          }}
          onClick={handleMinimize}
        >
          {isMinimized ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </i>
        <i
          class="material-icons icon-button"
          style={{
            fontSize: 22,
            lineHeight: 1,
            verticalAlign: "middle",
            height: "40px",
          }}
          onClick={handleClose}
        >
          close
        </i>
      </div>
    </div>
  );
};

export default UploadWindowHeader;
