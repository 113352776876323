import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setViewMode } from "../../redux/slices/user-preference-slice";
import { Button, ButtonGroup } from "reactstrap";
import { VIEW_MODES } from "../../constants/view-modes";
const ViewModeToggle = (props) => {
  const dispatch = useDispatch();

  const viewMode = useSelector((state) => state.userPreference.viewMode);

  const changeViewMode = (viewMode) => {
    dispatch(setViewMode(viewMode));
  };

  return (
    <div>
      <ButtonGroup>
        <Button
          className={`view-toggle-button ${
            viewMode === VIEW_MODES.LIST ? "active" : ""
          }`}
          color={viewMode === VIEW_MODES.LIST ? "primary" : "secondary"}
          onClick={() => changeViewMode(VIEW_MODES.LIST)}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, verticalAlign: "middle" }}
          >
            dehaze
          </i>
        </Button>
        <Button
          className={`view-toggle-button ${
            viewMode === VIEW_MODES.THUMBNAILS ? "active" : ""
          }`}
          color={viewMode === VIEW_MODES.THUMBNAILS ? "primary" : "secondary"}
          onClick={() => changeViewMode(VIEW_MODES.THUMBNAILS)}
        >
          <i
            className="material-icons"
            style={{ fontSize: 18, verticalAlign: "middle" }}
          >
            grid_view
          </i>
        </Button>
        {/* Future buttons can be added here */}
      </ButtonGroup>
    </div>
  );
};

export default ViewModeToggle;
