import React from "react";
import EmptyState from "../../../common/empty-state";

const HistoryTab = () => {
  return (
    <>
      <EmptyState text="Coming Soon. Please check back later." />
    </>
  );
};

export default HistoryTab;
