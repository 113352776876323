import React from "react";
import StorageBreadcrumb from "./storage-breadcrumb";
import ViewModeToggle from "./view-mode-toggle";
import NewItemButton from "./new-item-button";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import routes from "../../layout/routes/routes";
import PageTitle from "./page-title";
import Filters from "./filters/filters";
import EmptyTrashButton from "./empty-trash-button";
import ReaderModeButton from "./reader-mode-button";

const StorageNavbar = ({ filters = [] }) => {
  const location = useLocation();
  const orgId = useSelector((state) => state.user.org_id);

  const currentPath = location.pathname;
  const currentRoute = routes.find(
    (route) => route.layout + route.path === currentPath
  );

  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");
  const trashRoute = routes.find((route) => route.id === "TRASH");
  const homeRoute = routes.find((route) => route.id === "HOME");

  return (
    <div className="storage-navbar border-bottom">
      <div className="storage-navbar-top">
        {currentRoute == storageRoute ? (
          <StorageBreadcrumb />
        ) : (
          <PageTitle
            title={currentRoute == homeRoute ? "Suggested" : currentRoute?.name}
          />
        )}
        <ReaderModeButton />
        <ViewModeToggle />
        {currentRoute == storageRoute && <NewItemButton />}
        {currentRoute == trashRoute && <EmptyTrashButton />}
      </div>
      {filters.length > 0 && (
        <div className="storage-navbar-filters">
          <Filters filters={filters} orgId={orgId} />
        </div>
      )}
    </div>
  );
};

export default StorageNavbar;
