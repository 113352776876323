import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import {
  FaArrowRight,
  FaSquare,
  FaCircle,
  FaFont,
  FaSlash,
  FaHighlighter,
  FaEraser,
  FaPencilAlt,
  FaMousePointer,
} from "react-icons/fa";
import { MdBlurOn } from "react-icons/md";

const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const updatePosition = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", updatePosition);

    return () => window.removeEventListener("mousemove", updatePosition);
  }, []);

  return position;
};

const CursorOverlay = ({ selectedTool, isVisible }) => {
  const { x, y } = useMousePosition();

  const getIconForTool = () => {
    switch (selectedTool) {
      case "arrow":
        return <FaArrowRight />;
      case "rectangle":
        return <FaSquare />;
      case "circle":
        return <FaCircle />;
      case "line":
        return <FaSlash />;
      case "text":
        return <FaFont />;
      case "highlight":
        return <FaHighlighter />;
      case "pencil":
        return <FaPencilAlt />;
      case "eraser":
        return <FaEraser />;
      case "select":
        return <FaMousePointer />;
      case "pixelate":
        return <MdBlurOn />;
      default:
        return null;
    }
  };

  const icon = getIconForTool();

  if (!icon || !isVisible) return null;

  return createPortal(
    <div
      style={{
        position: "fixed",
        left: `${x + 15}px`,
        top: `${y + 15 - 10}px`,
        pointerEvents: "none",
        zIndex: 9999,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        borderRadius: "50%",
        padding: "5px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "30px",
        height: "30px",
        transition: "transform 0.1s ease-out",
        transform: "translate(0, 0) scale(1)",
      }}
    >
      {icon}
    </div>,
    document.body
  );
};

export default CursorOverlay;
