import React from "react";
import { FaExchangeAlt } from "react-icons/fa";

const RotateFlipOptions = ({ onFlipHorizontal, onFlipVertical }) => {
  return (
    <div className="rotate-flip-options">
      <button
        className="tool-button"
        onClick={onFlipHorizontal}
        title="Flip Horizontal"
      >
        <FaExchangeAlt />
      </button>
      <button
        className="tool-button"
        onClick={onFlipVertical}
        title="Flip Vertical"
      >
        <FaExchangeAlt style={{ transform: "rotate(90deg)" }} />
      </button>
    </div>
  );
};

export default RotateFlipOptions;
