import { useDispatch, useSelector } from "react-redux";
import AuthContainer from "../modules/proxima-auth/layout/auth-container";
import { login } from "../redux/slices/user-slice";

const AuthProvider = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const loginUser = (credentials,successHandler,errorHandler) => {
    
    dispatch(login({credentials,successHandler,errorHandler}));
  };

  return <AuthContainer user={user?.user??{}} token={user?.token} loginUser={loginUser}/>;
};

export default AuthProvider;
