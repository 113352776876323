import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FilterDropdown from "./filter-dropdown";
import routes from "../../../layout/routes/routes";

const TypeFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const typeParam = queryParams.get("type");

  const searchRoute = routes.find((route) => route.id === "SEARCH");

  const types = ["Folder", "Image"];

  const handleTypeSelect = (type) => {
    queryParams.set("type", type); // Set the selected type as a query parameter
    // navigate(`${location.pathname}?${queryParams.toString()}`);
    navigate(
      `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
    );
  };

  const handleClearTypes = () => {
    queryParams.delete("type"); // Clear the type filter from query parameters
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <FilterDropdown
        title="Type"
        items={types}
        isMultiSelect={false}
        showSearch={false}
        onItemSelect={handleTypeSelect}
        onClearSelection={handleClearTypes}
        initialSelectedItems={types.includes(typeParam) ? [typeParam] : []}
      />
    </>
  );
};

export default TypeFilter;
