import classnames from "classnames"; // Used to toggle active class
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { SHORTCUTS } from "../../../constants/shortcuts";
import FileInformationTab from "./file-information/file-information-tab";
import HistoryTab from "./history/history-tab";
import InsightsTab from "./insights/insights-tab";
import OcrTextTab from "./ocr-text/ocr-text-tab";

const DetailsTabs = ({ currentFile, fileDetails, userId, orgId }) => {
  const [activeTab, setActiveTab] = useState("1"); // Default to the first tab

  const shortcutKey = useSelector((state) => state.shortcut.shortcutKey);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (
      shortcutKey === SHORTCUTS.EDIT_TAGS ||
      shortcutKey === SHORTCUTS.EDIT_NOTE
    ) {
      setActiveTab("1");
    }
  }, [shortcutKey]);

  return (
    <div className="tabs-antstrap">
      <Nav tabs className="no-select">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            Insights
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggleTab("2")}
          >
            Text
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggleTab("3")}
          >
            File Information
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "4" })}
            onClick={() => toggleTab("4")}
          >
            History
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <InsightsTab
            key={currentFile._id}
            file={fileDetails}
            userId={userId}
            orgId={orgId}
          />
        </TabPane>
        <TabPane tabId="2">
          <OcrTextTab key={currentFile._id} file={fileDetails} />
        </TabPane>
        <TabPane tabId="3">
          <FileInformationTab key={currentFile._id} file={currentFile} />
        </TabPane>
        <TabPane tabId="4">
          <HistoryTab key={currentFile._id} file={currentFile} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default DetailsTabs;
