import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipCustom = ({ target, placement = "bottom", text }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Tooltip
      className="tooltip-custom"
      placement={placement}
      isOpen={tooltipOpen}
      target={target}
      toggle={toggle}
      autohide={true}
      delay={{ show: 100, hide: 100 }}
    >
      {text}
    </Tooltip>
  );
};

export default TooltipCustom;
