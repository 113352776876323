import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VIEW_MODES } from "../../constants/view-modes";
import routes from "../../layout/routes/routes";
import { setBreadCrumb } from "../../redux/slices/active-folder-slice";
import { fetchFolders } from "../../redux/slices/folders-slice";
import EmptyState from "../common/empty-state";
import FolderThumbnails from "./folder-thumbnails";

const RootFolder = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const folders = useSelector((state) => state.folders?.folders ?? []);
  const organization = useSelector((state) => state.organization);
  const viewMode = useSelector((state) => state.userPreference.viewMode);
  const [organizationData, setOrganizationData] = useState(null);
  // ToDo: Make it dynamic. Root foder can be called from both Shared Storage and My Storage
  const storageRoute = routes.find((route) => route.id === "SHARED_STORAGE");

  useEffect(() => {
    if (
      organization &&
      organization.id &&
      (!organizationData || organizationData.id != organization.id)
    ) {
      // alert(JSON.stringify(organization))
      setOrganizationData(organization);
    }
  }, [organization]);

  useEffect(() => {
    if (organizationData && organizationData.id) {
      // alert(JSON.stringify(organization))
      setIsLoading(true);
      dispatch(
        fetchFolders({
          orgId: organizationData.id,
          successHandler: () => {
            setIsLoading(false);
          },
        })
      );

      dispatch(setBreadCrumb([]));
    }
  }, [organizationData]);

  // Filter folders to only include those with null, empty, or non-existent parent_folder_id
  const rootFolders = folders.filter(
    (folder) =>
      folder.parent_folder_id === null ||
      folder.parent_folder_id === "" ||
      !folder.hasOwnProperty("parent_folder_id")
  );

  return !isLoading ? (
    rootFolders && rootFolders.length > 0 ? (
      viewMode === VIEW_MODES.THUMBNAILS ? (
        <FolderThumbnails folders={rootFolders} />
      ) : (
        <EmptyState text="Coming Soon. Please check back later." />
      )
    ) : (
      <EmptyState text="Folder is empty." />
    )
  ) : (
    <></>
  );
};

export default RootFolder;
