// import Folders from "../../components/folders/folders";
// import Settings from "../../components/settings/settings";
// import Home from "../../components/home/home";
// import SearchFiles from "../../components/search/search";
import routes from "../../modules/proxima-storage/layout/routes/routes";

export const tabs = [
  
];

let appRoutes = [...routes]
export default appRoutes;
