// userSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../constants/api-status";

import { downloadFile, downloadFolder } from "../../utils/download-utils";
import {
  fetchFileById,
  fetchFilesById,
  fetchImageFordownload,
  fetchUserDetailsById,
  moveImage,
  updateImageName,
  updateImageTagsAndNotes,
} from "../services/file-service";
import {
  fetchBreadcrumbForFolder,
  fetchFolderDetailsById,
  fetchFolderForDownload,
  fetchFoldersById,
  moveFolder,
  updateFolderName,
} from "../services/folder-service";
import { createFolderAction } from "../slices/folders-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "./favorites-slice";
import { moveFileToTrashAction, moveFolderToTrashAction } from "./trash-slice";
// import { API_STATUS } from "../../constants/api-status";

const initialState = {
  activeFolderId: null,
  activeFolderDetails: null,
  isShowUploadSection: false,
  breadcrumbs: [],
  rootBreadcrumb: {},
  folders: [],
  files: [],
  currentFileUserDetails: null,
  // Status : Loading, Success, Error
  statusFetchFolderContent: "",
  statusFetchFilesInFolder: "",
  statusFetchActiveFolderBreadcrumb: "",
  statusFetchUserDetailsForFile: "",
  statusMoveFolderAction: "",
  error: "",
  activeFileDetails: null,
  statusFetchActiveFileDetails: "",
  statusUpdateFileDetailAction: "",
};

export const fetchFolderContents = createAsyncThunk(
  "folder/folders",
  async ({ orgId, folderId }, { dispatch }) => {
    const data = await fetchFoldersById(orgId, folderId);
    const folders = data.content.folders;
    const files = data.content.images ? data.content.images : [];
    return { folders, files };
  }
);

export const fetchFolderDetails = createAsyncThunk(
  "folder/details",
  async ({ orgId, folderId }, { dispatch }) => {
    const data = await fetchFolderDetailsById(orgId, folderId);
    return data; // Return user data for updating the state
  }
);

export const fetchFilesInFolder = createAsyncThunk(
  "folder/files",
  async ({ orgId, folderId, userId }, { dispatch }) => {
    const data = await fetchFilesById(orgId, folderId, userId);
    return data.images ? data.images.filter((file) => !file.is_bin) : [];
  }
);

export const fetchUserDetailsForFile = createAsyncThunk(
  "folder/userDetails",
  async ({ orgId, userId }, { dispatch }) => {
    const data = await fetchUserDetailsById(orgId, userId);
    return data;
  }
);

export const fetchFileDetailsAction = createAsyncThunk(
  "folder/fileDetails",
  async ({ orgId, fileId, language }, { dispatch }) => {
    const data = await fetchFileById(orgId, fileId, language);
    return data.image; // Return user data for updating the state
  }
);

export const updateImageTagsAndNotesAction = createAsyncThunk(
  "folder/updateImageTagsAndNotes",
  async ({
    imageId,
    orgId,
    userId,
    tags,
    notes,
    title,
    heading,
    marathi_text,
    person_names,
    successHandler,
  }) => {
    const data = await updateImageTagsAndNotes(
      imageId,
      orgId,
      userId,
      tags,
      notes,
      title,
      heading,
      marathi_text,
      person_names,
      successHandler
    );
    return { data: data, fileId: imageId, title: title };
  }
);

export const renameFile = createAsyncThunk(
  "folder/renameFile",
  async ({ file, newName, successHandler }, { dispatch }) => {
    const data = await updateImageName(
      file._id,
      newName,
      file.org_id,
      file.lang,
      file.created_by,
      successHandler
    );
    return { data: data, fileId: file._id, newName: newName };
  }
);

export const renameFolder = createAsyncThunk(
  "folder/renameFolder",
  async ({ folder, newName, successHandler }, { dispatch }) => {
    const data = await updateFolderName(
      folder._id,
      folder.user_id,
      newName,
      successHandler
    );
    return { data: data, folderId: folder._id, newName: newName };
  }
);

export const fetchActiveFolderBreadcrumb = createAsyncThunk(
  "folder/breadcrumb",
  async ({ folderId }, { dispatch }) => {
    const data = await fetchBreadcrumbForFolder(folderId);
    return data; // Return user data for updating the state
  }
);

export const downloadImageAction = createAsyncThunk(
  "folder/downloadImage",
  async ({ orgId, imageId, fileName }) => {
    const data = await fetchImageFordownload(orgId, imageId);
    return { data: data, fileName: fileName };
  }
);

export const downloadFolderAction = createAsyncThunk(
  "folder/downloadFolder",
  async ({ orgId, folderId, folderName }, { dispatch }) => {
    const data = await fetchFolderForDownload(orgId, folderId);
    return { data: data, folderName: folderName };
  }
);

export const moveFolderAction = createAsyncThunk(
  "folder/moveFolderAction",
  async ({ folderId, newParentFolderId, successHandler }) => {
    const data = await moveFolder(folderId, newParentFolderId, successHandler);
    return {
      data: data,
      folderId: folderId,
      newParentFolderId: newParentFolderId,
    };
  }
);

export const moveImageAction = createAsyncThunk(
  "folder/moveImageAction",
  async ({ imageId, orgId, folderId, userId, language, successHandler }) => {
    const data = await moveImage(
      imageId,
      orgId,
      folderId,
      userId,
      language,
      successHandler
    );
    return {
      data: data,
      imageId: imageId,
      newFolderId: folderId,
    };
  }
);

const activeFolderSlice = createSlice({
  name: "activeFolder",
  initialState,
  reducers: {
    showUploadSection: (state, action) => {
      return {
        ...state,
        isShowUploadSection: true,
      };
    },
    hideUploadSection: (state, action) => {
      return {
        ...state,
        isShowUploadSection: false,
      };
    },
    toggleUploadSection: (state, action) => {
      return {
        ...state,
        isShowUploadSection: !state.isShowUploadSection,
      };
    },

    setActiveFolderId: (state, action) => {
      if (action.payload === null) {
        return {
          ...state,
          activeFolderId: null,
          files: [], // Reset files to empty array
          folders: [], // Reset folders to empty array
        };
      }
      return {
        ...state,
        activeFolderId: action.payload,
      };
    },

    setBreadCrumb: (state, action) => {
      return {
        ...state,
        breadcrumbs: action.payload,
      };
    },
    setRootBreadCrumb: (state, action) => {
      return {
        ...state,
        rootBreadcrumb: action.payload,
      };
    },
    updateFileLatestInformation: (state, action) => {
      let nState = { ...state };
      let files = nState.files.map((file) => {
        if (file._id == action.payload._id) {
          console.log("action");
          return action.payload;
        } else {
          return file;
        }
      });
      nState.status = "";
      nState.files = [...files];
      console.log(action);
      return nState;
    },
  },
  extraReducers: (builder) => {
    builder
      // ====================
      // Extra Reducers for : fetchFolderContents
      // ====================
      .addCase(fetchFolderContents.pending, (state) => {
        state.statusFetchFolderContent = API_STATUS.LOADING;
      })
      .addCase(fetchFolderContents.fulfilled, (state, action) => {
        state.folders = action.payload.folders;
        state.files = action.payload.files;
        state.statusFetchFolderContent = API_STATUS.SUCCESS;
      })
      .addCase(fetchFolderContents.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchFolderContent = API_STATUS.ERROR;
        // ToDo: Error handling
      })

      // ====================
      // Extra Reducers for : fetchFilesInFolder
      // ====================
      .addCase(fetchFilesInFolder.pending, (state) => {
        state.statusFetchFilesInFolder = API_STATUS.LOADING;
      })
      .addCase(fetchFilesInFolder.fulfilled, (state, action) => {
        state.files = action.payload;
        state.statusFetchFilesInFolder = API_STATUS.SUCCESS;
      })
      .addCase(fetchFilesInFolder.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchFilesInFolder = API_STATUS.ERROR;
        // ToDo: Error handling
      })

      // ====================
      // Extra Reducers for : fetchActiveFolderBreadcrumb
      // ====================
      .addCase(fetchActiveFolderBreadcrumb.pending, (state) => {
        state.statusFetchActiveFolderBreadcrumb = API_STATUS.LOADING;
      })
      .addCase(fetchActiveFolderBreadcrumb.fulfilled, (state, action) => {
        // Update the state with user data
        state.breadcrumbs = action.payload.hierarchy
          ? [...action.payload.hierarchy]
          : [];
        state.statusFetchActiveFolderBreadcrumb = API_STATUS.SUCCESS;
      })
      .addCase(fetchActiveFolderBreadcrumb.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchActiveFolderBreadcrumb = API_STATUS.ERROR;
        // ToDo: Error handling
      })

      // ====================
      // Extra Reducers for : fetchUserDetailsForFile
      // ====================
      .addCase(fetchUserDetailsForFile.pending, (state) => {
        state.statusFetchUserDetailsForFile = API_STATUS.LOADING;
      })
      .addCase(fetchUserDetailsForFile.fulfilled, (state, action) => {
        state.currentFileUserDetails = action.payload;
        state.statusFetchUserDetailsForFile = API_STATUS.SUCCESS;
      })
      .addCase(fetchUserDetailsForFile.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchUserDetailsForFile = API_STATUS.ERROR;
      })
      // ====================
      // Extra Reducers for : fetchFileDetailsAction
      // ====================
      .addCase(fetchFileDetailsAction.pending, (state) => {
        state.statusFetchActiveFileDetails = API_STATUS.LOADING;
      })
      .addCase(fetchFileDetailsAction.fulfilled, (state, action) => {
        state.activeFileDetails = action.payload;
        state.statusFetchActiveFileDetails = API_STATUS.SUCCESS;
      })
      .addCase(fetchFileDetailsAction.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusFetchActiveFileDetails = API_STATUS.ERROR;
      })
      // ====================
      // Extra Reducers for : updateImageTagsAndNotesAction
      // ====================
      .addCase(updateImageTagsAndNotesAction.fulfilled, (state, action) => {
        // ToDo: handle all update operations.
        // Currently updating alias only based on title

        const fileToUpdate = state.files.find(
          (file) => file._id === action.payload.fileId
        );

        // Update Alias
        if (fileToUpdate?.alias && !fileToUpdate?.is_alias_custom) {
          fileToUpdate.alias = action.payload.title;
        }
      })
      .addCase(updateImageTagsAndNotesAction.rejected, (state, action) => {
        state.error = action.payload;
      })

      // ====================
      // Extra Reducers for : moveFolderAction
      // ====================
      .addCase(moveFolderAction.pending, (state) => {
        state.statusMoveFolderAction = API_STATUS.LOADING;
      })
      .addCase(moveFolderAction.fulfilled, (state, action) => {
        state.statusMoveFolderAction = API_STATUS.SUCCESS;
        if (action.payload.folderId !== action.payload.newParentFolderId) {
          state.folders = state.folders.filter(
            (folder) => folder._id !== action.payload.folderId
          );
        }
      })
      .addCase(moveFolderAction.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusMoveFolderAction = API_STATUS.ERROR;
      })
      // ====================
      // Extra Reducers for : renameFile
      // ====================
      .addCase(renameFile.fulfilled, (state, action) => {
        // Rename is success, change file name in state
        state.files = state.files.map((file) =>
          file._id === action.payload.fileId
            ? { ...file, name: action.payload.newName }
            : file
        );
      })
      // ====================
      // Extra Reducers for : renameFolder
      // ====================
      .addCase(renameFolder.fulfilled, (state, action) => {
        // Rename is success, change folder name in state
        state.folders = state.folders.map((folder) =>
          folder._id === action.payload.folderId
            ? { ...folder, folder_name: action.payload.newName }
            : folder
        );
      })

      // ====================
      // Extra Reducers for : moveImageAction
      // ====================
      .addCase(moveImageAction.pending, (state) => {
        state.statusMoveImageAction = API_STATUS.LOADING;
      })
      .addCase(moveImageAction.fulfilled, (state, action) => {
        state.statusMoveImageAction = API_STATUS.SUCCESS;
        if (action.payload.newFolderId !== state.activeFolderId) {
          state.files = state.files.filter(
            (file) => file._id !== action.payload.imageId
          );
        }
      })
      .addCase(moveImageAction.rejected, (state, action) => {
        state.error = action.error.message;
        state.statusMoveImageAction = API_STATUS.ERROR;
      })
      // ====================
      // Extra Reducers for : downloadImageAction
      // ====================
      .addCase(downloadImageAction.pending, (state) => {
        state.statusDownloadImage = API_STATUS.LOADING;
      })
      .addCase(downloadImageAction.fulfilled, (state, action) => {
        state.statusDownloadImage = API_STATUS.SUCCESS;
        downloadFile(action.payload.data, action.payload.fileName);
      })
      .addCase(downloadImageAction.rejected, (state, action) => {
        state.statusDownloadImage = API_STATUS.ERROR;
        state.error = action.error.message;
      })
      // ====================
      // Extra Reducers for : downloadFolderAction
      // ====================
      .addCase(downloadFolderAction.pending, (state) => {
        state.statusDownloadFolder = API_STATUS.LOADING;
      })
      .addCase(downloadFolderAction.fulfilled, (state, action) => {
        state.statusDownloadFolder = API_STATUS.SUCCESS;
        downloadFolder(action.payload.data, action.payload.folderName);
      })
      .addCase(downloadFolderAction.rejected, (state, action) => {
        state.statusDownloadFolder = API_STATUS.ERROR;
        state.error = action.error.message;
      })

      // ====================
      // Extra Reducers for : createFolderAction from folders-slice
      // ====================
      .addCase(createFolderAction.fulfilled, (state, action) => {
        // After succes, add folder to folders list
        if (action.payload.folder != null) {
          state.folders = [
            ...state.folders,
            {
              ...action.payload.folder,
              _id: action.payload.data.folder_id,
            },
          ];
        }
      })

      // ====================
      // Extra Reducers for favorites
      // ====================
      .addCase(addToFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;

        // Add to files
        if (itemType && itemType === "image") {
          state.files = state.files.map((file) =>
            file._id === itemId ? { ...file, is_favorite: true } : file
          );
        }
        // Add to folders
        else if (itemType && itemType === "folder") {
          state.folders = state.folders.map((folder) =>
            folder._id === itemId ? { ...folder, is_favorite: true } : folder
          );
        }
      })
      .addCase(removeFromFavoritesAction.fulfilled, (state, action) => {
        const { itemId, itemType } = action.payload;

        // Remove from files
        if (itemType && itemType === "image") {
          state.files = state.files.map((file) =>
            file._id === itemId ? { ...file, is_favorite: false } : file
          );
        }
        // Remove from folders
        else if (itemType && itemType === "folder") {
          state.folders = state.folders.map((folder) =>
            folder._id === itemId ? { ...folder, is_favorite: false } : folder
          );
        }
      })

      // ====================
      // Extra Reducers for Trash
      // ====================
      .addCase(moveFolderToTrashAction.fulfilled, (state, action) => {
        state.folders = state.folders.filter(
          (folder) => folder._id !== action.payload.folder._id
        );
      })
      .addCase(moveFileToTrashAction.fulfilled, (state, action) => {
        state.files = state.files.filter(
          (file) => file._id !== action.payload.file._id
        );
      });
  },
});

export const {
  showUploadSection,
  hideUploadSection,
  toggleUploadSection,
  setActiveFolderId,
  setBreadCrumb,
  setRootBreadCrumb,
  updateFileLatestInformation,
} = activeFolderSlice.actions;

export const getBreadcrumbs = (state) => {
  return [state.activeFolder.rootBreadcrumb, ...state.activeFolder.breadcrumbs];
};
export const getActiveFolderId = (state) => state.activeFolder.activeFolderId;
export const getActiveFolderDEtails = (state) =>
  state.activeFolder.activeFolderDetails;
export const getStatusFetchFolderContents = (state) =>
  state.activeFolder.statusFetchFolderContent;
export const getStatusFetchFilesInFolder = (state) =>
  state.activeFolder.statusFetchFilesInFolder;
export const getStatusFetchActiveFolderBreadcrumb = (state) =>
  state.activeFolder.statusFetchActiveFolderBreadcrumb;
export const getStatusMoveFolder = (state) =>
  state.activeFolder.statusMoveFolder;
export const getCurrentFileUserDetails = (state) =>
  state.activeFolder.currentFileUserDetails;

export const getCurrentFileDetails = (state) =>
  state.activeFolder.activeFileDetails;

export const getStatusDownloadImage = (state) =>
  state.activeFolder.statusDownloadImage;
export const getStatusDownloadFolder = (state) =>
  state.activeFolder.statusDownloadFolder;
export default activeFolderSlice.reducer;
