import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    updateOrganization: (state, action) => {
       if(state.id == action.payload.id){
        return state
      }
      return {
        ...state,
        ...action.payload
      };
    },
  },
});

export const { updateOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
