import React from "react";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";

const ZoomOption = ({ zoom, setZoom }) => {
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 10, 200));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 10, 10));
  };

  return (
    <div className="zoom-controls">
      <button className="tool-button" title="Zoom Out" onClick={handleZoomOut}>
        <FaSearchMinus />
      </button>
      <div className="zoom-value">{zoom}%</div>
      <button className="tool-button" title="Zoom In" onClick={handleZoomIn}>
        <FaSearchPlus />
      </button>
    </div>
  );
};

export default ZoomOption;
