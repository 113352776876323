import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FilterDropdown from "./filter-dropdown";
import routes from "../../../layout/routes/routes";

const DateFilter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const afterParam = queryParams.get("after");
  const beforeParam = queryParams.get("before");

  const searchRoute = routes.find((route) => route.id === "SEARCH");

  const dates = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "This year",
    "Last year",
    "Custom date range",
  ];

  const handleDateSelect = (date) => {
    const today = new Date();
    let afterDate, beforeDate;

    switch (date[0]) {
      case "Today":
        afterDate = getFormattedDate(today);
        queryParams.set("after", afterDate);
        queryParams.delete("before");
        break;
      case "Yesterday":
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        afterDate = getFormattedDate(yesterday);
        beforeDate = getFormattedDate(today);
        queryParams.set("after", afterDate);
        queryParams.set("before", beforeDate);
        break;
      case "Last 7 days":
        const last7Days = new Date(today);
        last7Days.setDate(today.getDate() - 7);
        afterDate = getFormattedDate(last7Days);
        queryParams.set("after", afterDate);
        queryParams.delete("before");
        break;
      case "Last 30 days":
        const last30Days = new Date(today);
        last30Days.setDate(today.getDate() - 30);
        afterDate = getFormattedDate(last30Days);
        queryParams.set("after", afterDate);
        queryParams.delete("before");
        break;
      case "This year":
        afterDate = `${today.getFullYear()}-01-01`;
        queryParams.set("after", afterDate);
        queryParams.delete("before");
        break;
      case "Last year":
        const lastYear = new Date(today);
        lastYear.setFullYear(today.getFullYear() - 1);
        afterDate = `${lastYear.getFullYear()}-01-01`;
        beforeDate = `${lastYear.getFullYear()}-12-31`;
        queryParams.set("after", afterDate);
        queryParams.set("before", beforeDate);
        break;
      default:
        break;
    }
    // navigate(`${location.pathname}?${queryParams.toString()}`);
    navigate(
      `${searchRoute.layout}${searchRoute.path}?${queryParams.toString()}`
    );
  };

  const handleClearDate = () => {
    queryParams.delete("after"); // Clear the after date filter from query parameters
    queryParams.delete("before"); // Clear the before date filter from query parameters
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const mapRealDateToLabel = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (afterParam === getFormattedDate(today) && !beforeParam) return "Today";
    if (
      afterParam === getFormattedDate(yesterday) &&
      beforeParam === getFormattedDate(today)
    )
      return "Yesterday";

    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);
    if (afterParam === getFormattedDate(last7Days) && !beforeParam)
      return "Last 7 days";

    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);
    if (afterParam === getFormattedDate(last30Days) && !beforeParam)
      return "Last 30 days";

    if (afterParam === `${today.getFullYear()}-01-01` && !beforeParam)
      return "This year";

    const lastYear = new Date(today);
    lastYear.setFullYear(today.getFullYear() - 1);
    if (
      afterParam === `${lastYear.getFullYear()}-01-01` &&
      beforeParam === `${lastYear.getFullYear()}-12-31`
    )
      return "Last year";

    // If comes here, fallback to custom date range.
    // if (afterParam || beforeParam) return "Custom date range";
    // But instead of "Custom date range" label, show readable date ranges.
    if (afterParam && beforeParam) {
      const afterDate = new Date(afterParam);
      const beforeDate = new Date(beforeParam);
      return `${afterDate.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })} - ${beforeDate.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`;
    } else if (beforeParam) {
      const beforeDate = new Date(beforeParam);
      return `Before ${beforeDate.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`;
    } else if (afterParam) {
      const afterDate = new Date(afterParam);
      return `After ${afterDate.toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })}`;
    }

    return null;
  };

  const selectedLabel = mapRealDateToLabel();

  return (
    <>
      <FilterDropdown
        title="Modified"
        items={dates}
        isMultiSelect={false}
        showSearch={false}
        onItemSelect={handleDateSelect}
        onClearSelection={handleClearDate}
        initialSelectedItems={selectedLabel ? [selectedLabel] : []}
      />
    </>
  );
};

export default DateFilter;
