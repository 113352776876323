export default class Rectangle {
  constructor(startX, startY, width, height, color, lineWidth = 2) {
    this.startX = startX;
    this.startY = startY;
    this.width = width;
    this.height = height;
    this.color = color;
    this.lineWidth = lineWidth;
    this.isSelected = false;
  }

  draw(ctx) {
    ctx.save();
    ctx.beginPath();
    ctx.rect(this.startX, this.startY, this.width, this.height);
    ctx.strokeStyle = this.color;
    ctx.lineWidth = this.lineWidth / ctx.getTransform().a;
    ctx.stroke();
    ctx.restore();

    if (this.isSelected) {
      ctx.save();
      ctx.strokeStyle = "#00ff00";
      ctx.lineWidth = 2 / ctx.getTransform().a;
      ctx.setLineDash([5, 5]);
      ctx.strokeRect(
        this.startX - 2,
        this.startY - 2,
        this.width + 4,
        this.height + 4
      );
      ctx.restore();
    }
  }

  isPointInside(x, y) {
    return (
      x >= this.startX &&
      x <= this.startX + this.width &&
      y >= this.startY &&
      y <= this.startY + this.height
    );
  }

  setSelected(selected) {
    this.isSelected = selected;
  }

  move(dx, dy) {
    this.startX += dx;
    this.startY += dy;
  }
}
