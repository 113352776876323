import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE_URL } from "../../../../redux/services/base-url";
import { downloadImageAction } from "../../redux/slices/active-folder-slice";
import TooltipCustom from "../common/tooltip-custom";
import ImageEditor from "../image-editor/image-editor";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "../../redux/slices/favorites-slice";

const ImageControls = ({ file, zoomIn, zoomOut, resetZoom }) => {
  const dispatch = useDispatch();

  const [imageEditorModal, setImageEditorModal] = useState(false);
  const [isFovorite, setIsFavorite] = useState(false);

  const user = useSelector((state) => state.user);

  const toggleImageEditorModal = () => {
    setImageEditorModal(!imageEditorModal);
  };

  const handleAnnotateClick = () => {
    setImageEditorModal(true);
  };

  useEffect(() => {
    setIsFavorite(file.is_favorite);
  }, [file]);

  const handleFavoriteClick = () => {
    if (isFovorite) {
      dispatch(
        removeFromFavoritesAction({
          orgId: file.org_id,
          userId: user.id,
          itemId: file._id,
          itemType: "image",
        })
      )
        .unwrap()
        .then((response) => {
          setIsFavorite(false);
        })
        .catch((error) => {
          // Handle error
        });
    } else {
      dispatch(
        addToFavoritesAction({
          orgId: file.org_id,
          userId: user.id,
          itemId: file._id,
          itemType: "image",
        })
      )
        .unwrap()
        .then((response) => {
          setIsFavorite(true);
        })
        .catch((error) => {
          // Handle error
        });
    }
  };

  const handleDownloadClick = () => {
    dispatch(
      downloadImageAction({
        orgId: file.org_id,
        imageId: file._id,
        fileName: file.name,
      })
    );
  };

  return (
    <>
      <div className="image-options-container">
        <div className="zoom-controls">
          <i
            id="id-zoom-out"
            className="material-icons-outlined icon-button"
            onClick={zoomOut}
          >
            zoom_out
          </i>
          <i
            id="id-zoom-reset"
            className="material-icons-outlined icon-button"
            onClick={resetZoom}
          >
            crop_free
          </i>
          <i
            id="id-zoom-in"
            className="material-icons-outlined icon-button"
            onClick={zoomIn}
          >
            zoom_in
          </i>
        </div>
        <div className="divider"></div>
        <div>
          <div
            id="id-annotate"
            className="material-icons-outlined icon-button"
            onClick={handleAnnotateClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#212529"
            >
              <path d="m499-287 335-335-52-52-335 335 52 52Zm-261 87q-100-5-149-42T40-349q0-65 53.5-105.5T242-503q39-3 58.5-12.5T320-542q0-26-29.5-39T193-600l7-80q103 8 151.5 41.5T400-542q0 53-38.5 83T248-423q-64 5-96 23.5T120-349q0 35 28 50.5t94 18.5l-4 80Zm280 7L353-358l382-382q20-20 47.5-20t47.5 20l70 70q20 20 20 47.5T900-575L518-193Zm-159 33q-17 4-30-9t-9-30l33-159 165 165-159 33Z" />
            </svg>
          </div>
        </div>
        <div className="divider"></div>
        <div
          id="id-favorite"
          className="material-icons-outlined icon-button"
          onClick={handleFavoriteClick}
        >
          {isFovorite ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#212529"
            >
              <path d="m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#212529"
            >
              <path d="m354-287 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143ZM233-120l65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-350Z" />
            </svg>
          )}
        </div>
        <div
          id="id-download"
          className="material-icons-outlined icon-button"
          onClick={handleDownloadClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#212529"
          >
            <path d="M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
          </svg>
        </div>
      </div>
      {/* Image Editor Modal */}
      <ImageEditor
        isOpen={imageEditorModal}
        toggle={toggleImageEditorModal}
        imageUrl={`${API_BASE_URL}/api/images/image/${file?.name}/${file?.org_id}`}
        currentFile={file}
      />
      <TooltipCustom text={"Zoom out"} target="id-zoom-out" />
      <TooltipCustom text={"Zoom in"} target="id-zoom-in" />
      <TooltipCustom text={"Reset zoom"} target="id-zoom-reset" />
      <TooltipCustom text={"Annotate"} target="id-annotate" />
      <TooltipCustom
        text={isFovorite ? "Remove from favourites" : "Add to favourites"}
        target="id-favorite"
      />
      <TooltipCustom text={"Download"} target="id-download" />
    </>
  );
};

export default ImageControls;
