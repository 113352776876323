import React from "react";
import NoPhotoSVG from "../../../../assets/images/no-photo.svg";
import { API_BASE_URL } from "../../../../redux/services/base-url";
import TagsList from "../common/tags-list";

const ReaderModePagePanel = ({
  files,
  showOwner,
  showTags,
  showNote,
  pageRef,
}) => {
  return (
    <div className="reader-mode-page-container no-select ">
      <div className="rm-page" ref={pageRef}>
        {files.map((file, index) => (
          <div key={file._id || index} className="rm-post">
            {showOwner && (
              <div className="rm-post-owner-container">
                <img
                  src={NoPhotoSVG}
                  className="rm-post-owner-image"
                  draggable={false}
                />
                <div className="rm-post-owner-details">
                  <div className="rm-post-owner-label">
                    {file.user
                      ? file.user.first_name + " " + file.user.last_name
                      : "Unknown User"}
                  </div>
                  <div className="rm-post-timestamp">{file.created_at}</div>
                </div>
              </div>
            )}
            {showTags && file.tags?.length > 0 && (
              <div className="rm-post-tags-container">
                <TagsList tags={file.tags} isEditing={false} select={false} />
              </div>
            )}
            {showNote && file.notes?.length > 0 && (
              <div className="rm-post-note">{file.notes}</div>
            )}
            <div className="rm-post-image-container">
              <img
                className="rm-post-image"
                src={`${API_BASE_URL}/api/images/image/${file?.name}/${file?.org_id}`}
                alt={file.name}
                draggable={false}
              />
            </div>
            {/* {index < files.length - 1 && (
              <div className="rm-post-divider"></div>
            )} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReaderModePagePanel;
