// import { createBrowserHistory } from "history";
import React from "react";
import { Provider } from "react-redux";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
// import "./design-system.css"
import "./assets/dist/design-system-antstrap.css";
// import "./design-system.css"
import "./assets/scss/_file-manager.scss"
import AuthContainer from "./modules/proxima-auth/layout/auth-container";
import Dashboard from "./layout/dashboard";
import { getStore } from "./redux/store";
import AuthProvider from "./layout/auth";

const MainApp = () => {
  const store = getStore()
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route
            path="/auth/*"
            Component={(props) => (
              <Provider store={store}>
                <AuthProvider {...props} />
              </Provider>
            )}
          ></Route>
          <Route
            path="/*"
            Component={(props) => (
              <Provider store={store}>
                <Dashboard {...props} />
              </Provider>
            )}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
};

export default MainApp;
