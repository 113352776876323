export default class Line {
  constructor(startX, startY, endX, endY, color, width = 2) {
    this.startX = startX;
    this.startY = startY;
    this.endX = endX;
    this.endY = endY;
    this.color = color;
    this.width = width;
    this.isSelected = false;
    this.originalWidth = width;
  }

  draw(ctx) {
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(this.startX, this.startY);
    ctx.lineTo(this.endX, this.endY);
    ctx.strokeStyle = this.color;
    ctx.lineWidth = this.originalWidth / Math.abs(ctx.getTransform().a);
    ctx.stroke();
    ctx.restore();
    if (this.isSelected) {
      ctx.save();
      ctx.strokeStyle = "#00ff00";
      ctx.lineWidth = 2 / ctx.getTransform().a;
      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.moveTo(this.startX, this.startY);
      ctx.lineTo(this.endX, this.endY);
      ctx.stroke();
      ctx.restore();
    }
  }

  isPointInside(x, y) {
    const threshold = 5;
    const dx = this.endX - this.startX;
    const dy = this.endY - this.startY;
    const length = Math.sqrt(dx * dx + dy * dy);
    const dot =
      ((x - this.startX) * dx + (y - this.startY) * dy) / (length * length);
    const closestX = this.startX + dot * dx;
    const closestY = this.startY + dot * dy;
    const distanceSquared =
      (x - closestX) * (x - closestX) + (y - closestY) * (y - closestY);
    return distanceSquared < threshold * threshold && dot >= 0 && dot <= 1;
  }

  setSelected(selected) {
    this.isSelected = selected;
  }

  move(dx, dy) {
    this.startX += dx;
    this.startY += dy;
    this.endX += dx;
    this.endY += dy;
  }
}
