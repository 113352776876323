import { createSlice } from "@reduxjs/toolkit";

const shortcutSlice = createSlice({
  name: "shortcut",
  initialState: {
    shortcutKey: null,
  },
  reducers: {
    setShortcutKey: (state, action) => {
      state.shortcutKey = action.payload;
    },
    resetShortcutKey: (state) => {
      state.shortcutKey = null;
    },
  },
});

export const { setShortcutKey, resetShortcutKey } = shortcutSlice.actions;
export default shortcutSlice.reducer;
