import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { SHORTCUTS } from "../../../../constants/shortcuts";
import {
  fetchTagsAction,
  getTagSuggestions,
} from "../../../../redux/slices/search-slice";
import { resetShortcutKey } from "../../../../redux/slices/shortcut-slice";
import InputWithSuggestions from "../../../common/input-with-suggestions";
import PlusButton from "../../../common/plus-button";
import TagsList from "../../../common/tags-list";
import TooltipCustom from "../../../common/tooltip-custom";

const TagsComponent = ({ initialTags, onSave, orgId }) => {
  const dispatch = useDispatch();

  const [tags, setTags] = useState(initialTags || []);
  const [isEditing, setIsEditing] = useState(false);
  const [currentInput, setCurrentInput] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const inputRef = useRef(null);

  const suggestions = useSelector(getTagSuggestions);
  const shortcutKey = useSelector((state) => state.shortcut.shortcutKey);

  useEffect(() => {
    if (initialTags) {
      setTags(initialTags);
    } else {
      setTags([]);
    }
  }, [initialTags]);

  useEffect(() => {
    if (orgId) {
      dispatch(fetchTagsAction(orgId));
    }
  }, [orgId]);

  useEffect(() => {
    if (currentInput.trim() !== "") {
      const newFilteredSuggestions = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(currentInput.toLowerCase())
      );
      setFilteredSuggestions(newFilteredSuggestions);
    } else {
      setFilteredSuggestions([]);
    }
  }, [currentInput, suggestions]);

  // Auto-focus the input when entering edit mode
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus(); // Focus the input field
    }
  }, [isEditing]);

  useEffect(() => {
    if (shortcutKey === SHORTCUTS.EDIT_TAGS) {
      if (!isEditing) {
        setIsEditing(true);
      } else if (isEditing && inputRef.current) {
        inputRef.current.focus(); // Focus the input field
      }
      dispatch(resetShortcutKey()); // Reset shortcut after handling
    }
  }, [shortcutKey]);

  const handleSuggestionClick = (suggestion) => {
    if (
      !tags.includes(suggestion) &&
      !tags?.some((tag) => tag.toLowerCase() === suggestion.toLowerCase())
    ) {
      setTags([...tags, suggestion]);
    }
    setCurrentInput("");
    setFilteredSuggestions([]);
  };

  const handleInputChange = (e) => {
    setCurrentInput(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === ",") {
      e.preventDefault();
      const trimmedInput = currentInput.trim();
      if (
        trimmedInput !== "" &&
        !tags?.some((tag) => tag.toLowerCase() === trimmedInput.toLowerCase())
      ) {
        setTags([...tags, trimmedInput]);
      }
      setCurrentInput("");
    } else if (e.key === "Enter") {
      handleSave();
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleTagRemove = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const handleClearAll = () => {
    setTags([]);
  };

  const handleSave = () => {
    const trimmedInput = currentInput.trim();
    if (
      trimmedInput !== "" &&
      !tags?.some((tag) => tag.toLowerCase() === trimmedInput.toLowerCase())
    ) {
      const updatedTags = [...tags, trimmedInput];
      setTags(updatedTags);
      onSave(updatedTags);
    } else {
      onSave(tags); // If no new tag is added, just save the current tags
    }
    setCurrentInput("");
    setIsEditing(false);
  };

  const handleCancel = () => {
    setTags(initialTags);
    setCurrentInput("");
    setIsEditing(false);
  };

  return (
    <>
      <div className="tags-component">
        <div className="tab-section-header no-select">
          <div className="title">Tags</div>
          {!isEditing && tags?.length != 0 && (
            <>
              <i
                id={"id-edit-tags"}
                className="material-icons icon-button"
                onClick={handleEdit}
              >
                edit
              </i>
              <TooltipCustom
                text={"Edit Tags (" + SHORTCUTS.EDIT_TAGS + ")"}
                target="id-edit-tags"
              />
            </>
          )}
        </div>
        <TagsList
          tags={tags}
          isEditing={isEditing}
          onRemove={handleTagRemove}
          select={false}
        />
        {isEditing && (
          <div className="mt-2">
            <InputWithSuggestions
              ref={inputRef}
              placeholderText={"Type tags separated by commas"}
              currentInput={currentInput}
              handleInputChange={handleInputChange}
              handleInputKeyPress={handleInputKeyPress}
              suggestions={filteredSuggestions}
              handleSuggestionClick={handleSuggestionClick}
            />
            <div className="d-flex mt-2 justify-content-between">
              <div>
                <Button color="primary" size="sm" onClick={handleSave}>
                  Save
                </Button>
                <Button color="link" size="sm" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
              {tags?.length > 0 && (
                <Button color="link" size="sm" onClick={handleClearAll}>
                  Clear all
                </Button>
              )}
            </div>
          </div>
        )}
        {!isEditing && tags?.length == 0 && (
          <>
            <PlusButton label={"Add Tags"} onClick={() => setIsEditing(true)} />
            <TooltipCustom
              text={"Add Tags (" + SHORTCUTS.EDIT_TAGS + ")"}
              target="id_add-tags"
            />
          </>
        )}
      </div>
    </>
  );
};

export default TagsComponent;
