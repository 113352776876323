import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const ContextMenu = ({ menuText, menuItems, type }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen((prevState) => !prevState);

  return (
    <>
      <Dropdown
        isOpen={menuOpen}
        toggle={toggleMenu}
        className="context-menu"
        {...(type == "more" ? { direction: "right" } : {})}
      >
        {type == "more" ? (
          <DropdownToggle
            size={"sm"}
            className="bg-transparent text-dark border-0 p-0"
          >
            {" "}
            <i className="material-icons icon-button">more_vert</i>
          </DropdownToggle>
        ) : (
          <DropdownToggle color="primary">
            {menuText}{" "}
            <i
              className="material-icons"
              style={{ fontSize: 18, lineHeight: 0, verticalAlign: "middle" }}
            >
              {menuOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
            </i>
          </DropdownToggle>
        )}
        <DropdownMenu>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.divider ? (
                <DropdownItem divider />
              ) : (
                <DropdownItem
                  onClick={item.onClick}
                  className={item.disabled ? "disabled" : ""}
                >
                  {item.icon && (
                    <i
                      className={
                        item.filledIcon
                          ? "material-icons"
                          : "material-icons-outlined"
                      }
                    >
                      {item.icon}
                    </i>
                  )}
                  {item.label}
                </DropdownItem>
              )}
            </React.Fragment>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default ContextMenu;
