import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getActiveFolderId,
  hideUploadSection,
} from "../../../redux/slices/active-folder-slice";
import FilesToUpload from "./files-to-upload";
import {
  clearSelectedFilesToUpload,
  getSelectedFilesToUpload,
} from "../../../redux/slices/upload-slice";
import ConfirmCancelUploadModal from "../modals/ConfirmCancelUploadModal";
import UploadWindowHeader from "./upload-window-header";

const UploadWindow = () => {
  const dispatch = useDispatch();
  const isShowUploadSection = useSelector(
    (state) => state.activeFolder.isShowUploadSection
  );
  const selectedFilesToUpload = useSelector(getSelectedFilesToUpload);
  const activeFolderId = useSelector(getActiveFolderId);

  const [isMinimized, setIsMinimized] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmCancelModalOpen, setIsConfirmCancelModalOpen] =
    useState(false);
  const toggleConfirmCancelModal = () => {
    setIsConfirmCancelModalOpen(!isConfirmCancelModalOpen);
  };

  useEffect(() => {
    if (isShowUploadSection) {
      setIsVisible(true);
    }
  }, [isShowUploadSection]);

  useEffect(() => {
    // Do not allow upload files in root folder
    if (!activeFolderId && selectedFilesToUpload?.length == 0) {
      closeUploadWindow();
    }
  }, [activeFolderId, selectedFilesToUpload]);

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClose = () => {
    // Check if any files are still uploading or in the queue
    const hasPendingFiles = selectedFilesToUpload.some(
      (file) => file.isUploading || file.isInQueue
    );
    if (hasPendingFiles) {
      toggleConfirmCancelModal();
    } else {
      dispatch(clearSelectedFilesToUpload());
      closeUploadWindow();
    }
  };

  const handleCancelUploads = () => {
    // ToDo: @Dhiraj Logic to cancel any ongoing uploads
    console.log("Uploads canceled");
    toggleConfirmCancelModal();
    dispatch(clearSelectedFilesToUpload());
    closeUploadWindow();
  };

  const closeUploadWindow = () => {
    dispatch(hideUploadSection());
    setIsVisible(false);
    setIsMinimized(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className={`upload-section${isMinimized ? " minimized" : ""} no-select`}
    >
      <UploadWindowHeader
        isMinimized={isMinimized}
        handleMinimize={handleMinimize}
        handleClose={handleClose}
      />
      <div className="upload-body-container">
        <div className={isMinimized ? "upload-body d-none" : "upload-body"}>
          <FilesToUpload />
        </div>
      </div>
      <ConfirmCancelUploadModal
        isOpen={isConfirmCancelModalOpen}
        toggle={toggleConfirmCancelModal}
        onCancel={handleCancelUploads}
      />
    </div>
  );
};

export default UploadWindow;
