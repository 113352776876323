import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { downloadFolderAction } from "../../../redux/slices/active-folder-slice";
import {
  addToFavoritesAction,
  removeFromFavoritesAction,
} from "../../../redux/slices/favorites-slice";
import {
  deleteFolderForeverAction,
  moveFolderToTrashAction,
  restoreFromTrashAction,
} from "../../../redux/slices/trash-slice";
import ConfirmationModal from "../../common/confirmation-modal";
import ContextMenu from "../../common/context-menu";
import FolderTreeSelectionModal, {
  FOLDER_TREE_SELECTION_MODES,
} from "../../folder-tree/folder-tree-selection-modal";
import RenameModal from "../modals/rename-modal";

const FolderContextMenu = ({ folder,userId, onFolderOpen }) => {
  const dispatch = useDispatch();

  const [renameModal, setRenameModal] = useState(false);
  const [folderTreeSelectionModal, setFolderTreeSelectionModal] =
    useState(false);
  const [folderTreeSelectionType, setFolderTreeSelectionType] = useState("");
  const [deleteForeverConfirmationModal, setdeleteForeverConfirmationModal] =
    useState(false);

  const toggleRenameModal = () => {
    setRenameModal(!renameModal);
  };
  const toggleFolderTreeSelectionModal = () => {
    setFolderTreeSelectionModal(!folderTreeSelectionModal);
  };
  const toggleDeleteForeverConfirmationModal = () => {
    setdeleteForeverConfirmationModal(!deleteForeverConfirmationModal);
  };

  const handleClicks = () => {
    /* Dummy method. ToDo: Handle all clicks */
  };

  const handleRenameClick = () => {
    toggleRenameModal();
  };

  const handleDownloadClick = () => {
    dispatch(
      downloadFolderAction({
        orgId: folder.org_id,
        folderId: folder._id,
        folderName: folder.folder_name,
      })
    );
  };

  const handleCopyToClick = () => {
    setFolderTreeSelectionType(FOLDER_TREE_SELECTION_MODES.COPY_FOLDER);
    toggleFolderTreeSelectionModal();
  };

  const handleMoveToClick = () => {
    setFolderTreeSelectionType(FOLDER_TREE_SELECTION_MODES.MOVE_FOLDER);
    toggleFolderTreeSelectionModal();
  };

  const handleAddToFavoritesClick = () => {
    dispatch(
      addToFavoritesAction({
        orgId: folder.org_id,
        userId: userId,
        itemId: folder._id,
        itemType: "folder",
      })
    );
  };

  const handleRemoveFromFavoritesClick = () => {
    dispatch(
      removeFromFavoritesAction({
        orgId: folder.org_id,
        userId: userId,
        itemId: folder._id,
        itemType: "folder",
      })
    );
  };

  const handleMoveToTrashClick = () => {
    dispatch(moveFolderToTrashAction(folder));
  };

  const handleRestoreFromTrashClick = () => {
    dispatch(
      restoreFromTrashAction({
        item: folder,
        itemType: "folder",
      })
    );
  };

  const handleDeleteForeverClick = () => {
    toggleDeleteForeverConfirmationModal();
  };

  const handleDeleteForever = () => {
    dispatch(
      deleteFolderForeverAction({
        folder: folder,
        successHandler: () => {
          toggleDeleteForeverConfirmationModal();
        },
      })
    );
  };

  const menuItems = [
    {
      label: "Open",
      icon: "visibility",
      onClick: onFolderOpen,
    },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Download",
      icon: "download",
      onClick: handleDownloadClick,
    },
    {
      label: "Rename",
      icon: "edit",
      onClick: handleRenameClick,
    },
    {
      divider: true, // This will add a divider
    },
    // {
    //   label: "Copy to...",
    //   icon: "copy",
    //   onClick: handleCopyToClick,
    // },
    {
      label: "Move to...",
      icon: "drive_file_move",
      onClick: handleMoveToClick,
    },
    {
      divider: true, // This will add a divider
    },
    folder.is_favorite
      ? {
          label: "Remove from favourites",
          icon: "star",
          filledIcon: true,
          onClick: handleRemoveFromFavoritesClick,
        }
      : {
          label: "Add to favourites",
          icon: "grade",
          onClick: handleAddToFavoritesClick,
        },
    {
      divider: true, // This will add a divider
    },
    {
      label: "Move to trash",
      icon: "delete",
      onClick: handleMoveToTrashClick,
    },
  ];

  const trashMenuItems = [
    {
      label: "Restore",
      icon: "restore",
      onClick: handleRestoreFromTrashClick,
    },
    {
      label: "Delete Forever",
      icon: "delete_forever",
      onClick: handleDeleteForeverClick,
    },
  ];

  return (
    <>
      <ContextMenu
        menuText="New"
        menuItems={folder.is_bin ? trashMenuItems : menuItems}
        type="more"
      />
      <RenameModal
        isOpen={renameModal}
        toggle={toggleRenameModal}
        item={folder}
        isFile={false}
      />
      <FolderTreeSelectionModal
        isOpen={folderTreeSelectionModal}
        toggle={toggleFolderTreeSelectionModal}
        type={folderTreeSelectionType}
        item={folder}
      />
      <ConfirmationModal
        isOpen={deleteForeverConfirmationModal}
        toggle={toggleDeleteForeverConfirmationModal}
        title="Delete folder forever?"
        description={`"${folder.name}" and all its contents will be deleted permanently and you won't be able to restore it again.`}
        confirmButtonText="Delete forever"
        onConfirm={handleDeleteForever}
        isDanger={true}
      />
    </>
  );
};

export default FolderContextMenu;
