import React from "react";
import { NavLink } from "react-router-dom";
import { Collapse, NavbarText } from "reactstrap";

const SidebarMenu = ({
  menu,
  createLinks,
  activeRoute,
  setCollapseState,
  getCollapseInitialState,
  collapseState,
  toggleSidebar,
  isMobile,
}) => {
  if (menu.redirect || (isMobile && menu.isHiddenInMobile)) {
    return null;
  }
  if (menu.collapse) {
    var st = {};
    st[menu["state"]] = !collapseState[menu.state];
    return (
      <>
        {menu.headerText && <NavbarText>{menu.headerText}</NavbarText>}
        <li className={getCollapseInitialState(menu.views) ? "active" : ""}>
          <a
            href="#pablo"
            data-toggle="collapse"
            aria-expanded={collapseState[menu.state]}
            onClick={(e) => {
              e.preventDefault();
              setCollapseState(st);
            }}
          >
            {menu.icon !== undefined ? (
              <>
                <i className={menu.icon}>{menu.iconName}</i>
                <p>
                  {menu.name}
                  <b className="caret" />
                </p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{menu.mini}</span>
                <span className="sidebar-normal">
                  {menu.name}
                  <b className="caret" />
                </span>
              </>
            )}
          </a>
          <Collapse isOpen={collapseState[menu.state]}>
            <ul className="nav">{createLinks(menu.views)}</ul>
          </Collapse>
        </li>
      </>
    );
  }
  return (
    <>
      {menu.headerText && <NavbarText>{menu.headerText}</NavbarText>}
      <li className={activeRoute(menu.layout + menu.path)} >
        <NavLink
          to={menu.layout + menu.path}
          activeClassName=""
          onClick={toggleSidebar}
        >
          {menu.icon !== undefined ? (
            <>
              <i className={menu.icon}>{menu.iconName}</i>
              <p>{menu.name}</p>
            </>
          ) : (
            <>
              <span className="sidebar-mini-icon">{menu.mini}</span>
              <span className="sidebar-normal">{menu.name}</span>
            </>
          )}
        </NavLink>
      </li>
    </>
  );
};

export default SidebarMenu;
