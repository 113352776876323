import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateImageTagsAndNotesAction } from "../../../../redux/slices/active-folder-slice";
import EditableTextCard from "../../../common/editable-text-card";
import EmptyState from "../../../common/empty-state";

const OcrTextComponent = ({ file }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const hasContent =
    file?._doc?.title ||
    file?._doc?.heading ||
    file?._doc?.marathi_text ||
    file?._doc?.person_names?.length > 0;

  const handleDispatchUpdate = (updatedFields) => {
    const payload = {
      imageId: file._doc._id,
      orgId: file._doc.org_id,
      userId: user.id,
      tags: file._doc?.tags || [],
      notes: file._doc?.notes || "",
      title: file._doc?.title || "",
      heading: file._doc?.heading || "",
      marathi_text: file._doc?.marathi_text || "",
      person_names: file._doc?.person_names || [],
      ...updatedFields,
    };

    dispatch(updateImageTagsAndNotesAction(payload));
  };

  const handleSaveHeading1 = (newValue) => {
    handleDispatchUpdate({ title: newValue });
  };

  const handleSaveHeading2 = (newValue) => {
    handleDispatchUpdate({ heading: newValue });
  };

  const handleSaveText = (newValue) => {
    handleDispatchUpdate({ marathi_text: newValue });
  };

  const handleSavePeople = (newValue) => {
    handleDispatchUpdate({
      person_names: newValue.split(",").map((name) => name.trim()),
    });
  };

  return (
    <>
      <div className="ocr-text-component">
        {hasContent ? (
          <>
            <div className="ocr-disclaimer">
              <strong>Disclaimer:</strong> This text was extracted using Optical
              Character Recognition (OCR) and may not always be 100% accurate,
              especially for complex or unclear images.
            </div>

            {file?._doc?.title ? (
              <EditableTextCard
                title="Heading 1"
                initialText={file._doc.title}
                editable={true}
                onSave={handleSaveHeading1}
                placeholder="Type heading 1 here..."
              />
            ) : (
              <></>
            )}
            {file?._doc?.title ? (
              <EditableTextCard
                title="Heading 2"
                initialText={file._doc.heading}
                editable={true}
                onSave={handleSaveHeading2}
                placeholder="Type heading 2 here..."
              />
            ) : (
              <></>
            )}
            {file?._doc?.marathi_text ? (
              <EditableTextCard
                title="Text"
                initialText={file._doc.marathi_text}
                editable={true}
                onSave={handleSaveText}
                placeholder="Type text here..."
              />
            ) : (
              <></>
            )}
            {file?._doc?.person_names?.length > 0 ? (
              <EditableTextCard
                title="People"
                initialText={file._doc.person_names.join(", ")}
                editable={true}
                onSave={handleSavePeople}
                placeholder="Type people here..."
              />
            ) : (
              <></>
            )}
          </>
        ) : (
          <EmptyState text="No data available. The text extraction process may not have detected any content in the image. If the image contains text, please ensure it is clear and readable for better results." />
        )}
      </div>
    </>
  );
};

export default OcrTextComponent;
