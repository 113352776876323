export default class Highlighter {
  constructor(startX, startY, color, width = 2) {
    this.points = [{ x: startX, y: startY }];
    this.color = color;
    this.width = width * 3;
    this.isSelected = false;
    this.originalWidth = width * 3;
  }

  addPoint(x, y) {
    this.points.push({ x, y });
  }

  draw(ctx) {
    if (this.points.length < 2) return;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(this.points[0].x, this.points[0].y);

    for (let i = 1; i < this.points.length; i++) {
      ctx.lineTo(this.points[i].x, this.points[i].y);
    }

    ctx.strokeStyle = this.color + "40";
    ctx.lineWidth = this.originalWidth / Math.abs(ctx.getTransform().a);
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.stroke();
    ctx.restore();
    if (this.isSelected) {
      ctx.save();
      ctx.strokeStyle = "#00ff00";
      ctx.lineWidth = 2 / ctx.getTransform().a;
      ctx.setLineDash([5, 5]);
      ctx.beginPath();
      ctx.moveTo(this.points[0].x, this.points[0].y);
      for (let i = 1; i < this.points.length; i++) {
        ctx.lineTo(this.points[i].x, this.points[i].y);
      }
      ctx.stroke();
      ctx.restore();
    }
  }

  isPointInside(x, y) {
    const threshold = 5;
    for (let i = 1; i < this.points.length; i++) {
      const x1 = this.points[i - 1].x;
      const y1 = this.points[i - 1].y;
      const x2 = this.points[i].x;
      const y2 = this.points[i].y;
      const dx = x2 - x1;
      const dy = y2 - y1;
      const length = Math.sqrt(dx * dx + dy * dy);
      const dot = ((x - x1) * dx + (y - y1) * dy) / (length * length);
      const closestX = x1 + dot * dx;
      const closestY = y1 + dot * dy;
      const distanceSquared =
        (x - closestX) * (x - closestX) + (y - closestY) * (y - closestY);
      if (distanceSquared < threshold * threshold && dot >= 0 && dot <= 1) {
        return true;
      }
    }
    return false;
  }

  setSelected(selected) {
    this.isSelected = selected;
  }

  move(dx, dy) {
    this.points = this.points.map((point) => ({
      x: point.x + dx,
      y: point.y + dy,
    }));
  }
}
