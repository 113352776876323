import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Input } from "reactstrap";

const InputWithSuggestions = forwardRef(
  (
    {
      currentInput,
      handleInputChange,
      handleInputKeyPress,
      placeholderText,
      suggestions,
      handleSuggestionClick,
    },
    ref
  ) => {
    const [selectedIndex, setSelectedIndex] = useState(-1); // To track selected suggestion
    const suggestionsRef = useRef(null); // Reference to suggestions container

    // Handle keyboard navigation (down arrow, up arrow, Enter)
    const handleKeyDown = (e) => {
      if (suggestions.length > 0) {
        if (e.key === "ArrowDown") {
          // Move down through the list
          e.preventDefault(); // Prevent cursor from moving in input field
          if (selectedIndex < suggestions.length - 1) {
            // Move down through the list only if not at the last item
            setSelectedIndex((prevIndex) => prevIndex + 1);
          }
        } else if (e.key === "ArrowUp") {
          // Move up through the list
          e.preventDefault();
          if (selectedIndex > 0) {
            // Move up through the list only if not at the first item
            setSelectedIndex((prevIndex) => prevIndex - 1);
          }
        } else if (e.key === "Enter" && selectedIndex >= 0) {
          // Select the current suggestion
          handleSuggestionClick(suggestions[selectedIndex]);
          e.stopPropagation(); // Stop propagation to parent
        } else if (e.key === "Enter") {
          // Allow Enter to propagate if no suggestion is selected
          if (selectedIndex === -1 && handleInputKeyPress) {
            handleInputKeyPress(e);
          }
        } else {
          // Allow parent component's key press handling (if any)
          if (handleInputKeyPress) {
            handleInputKeyPress(e);
          }
        }
      } else {
        // Allow parent component's key press handling (if any)
        if (handleInputKeyPress) {
          handleInputKeyPress(e);
        }
      }
    };

    // Reset the selectedIndex if the suggestions list changes
    useEffect(() => {
      setSelectedIndex(-1);
    }, [suggestions]);

    // Scroll the selected suggestion into view
    useEffect(() => {
      if (suggestionsRef.current) {
        const selectedItem = suggestionsRef.current.children[selectedIndex];
        if (selectedItem) {
          selectedItem.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }
    }, [selectedIndex, suggestions]);

    return (
      <div className="input-with-suggestions">
        <Input
          type="text"
          value={currentInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholderText}
          innerRef={ref}
        />
        {suggestions?.length > 0 && (
          <div className="suggestions-dropdown" ref={suggestionsRef}>
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className={`suggestion-item ${
                  selectedIndex === index ? "selected" : ""
                }`} // Add "selected" class if this suggestion is selected
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

export default InputWithSuggestions;
