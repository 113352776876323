import React, { useState } from "react";

const FolderNode = ({
  folder,
  onSelect,
  isDisabled,
  isSelected,
  storageType,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = () => {
    if (!isDisabled(folder)) {
      onSelect(folder);
    }
  };

  return (
    <div className="folder-node-container">
      <div
        className={`folder-node ${
          isDisabled(folder) ? "disabled" : isSelected(folder) ? "selected" : ""
        }`}
      >
        <div
          className="folder-node-toggle"
          onClick={isDisabled(folder) ? () => {} : handleToggle} // allow toggle
        >
          {isOpen ? (
            <i className="material-icons">arrow_drop_down</i>
          ) : (
            <i className="material-icons">arrow_right</i>
          )}
        </div>
        <div className="folder-node-info" onClick={handleSelect}>
          <i className="material-icons">
            {storageType === "shared-storage" ? "folder_shared" : "folder"}
          </i>
          <div className="folder-node-label">{folder.name}</div>
        </div>
      </div>
      {isOpen && (
        <div className="folder-node-children">
          {folder.children?.map((child) => (
            <FolderNode
              key={child._id}
              folder={child}
              onSelect={onSelect}
              isDisabled={isDisabled}
              isSelected={isSelected}
              storageType={storageType}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FolderNode;
