import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmCancelUploadModal = ({ isOpen, toggle, onCancel }) => {
  return (
    <Modal centered isOpen={isOpen} toggle={toggle} className="custom-modal">
      <ModalHeader className="border-bottom-0" toggle={toggle}>
        Cancel all uploads
      </ModalHeader>
      <ModalBody>
        File uploads are in progress. Would you like to cancel all ongoing
        uploads?
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Continue Uploads
        </Button>
        <Button
          color="link"
          className="color-light font-weight-bold"
          onClick={onCancel}
        >
          Cancel Uploads
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmCancelUploadModal;
