// apiService.js

import {
  createHeaders,
  handleErrors,
  parseJSON,
  retriveFromCache,
  storeInCache,
} from "./api-middleware";
import { API_BASE_URL } from "./base-url";

// Function to perform a POST request for login
export const loginUser = async (credentials, successHandler, errorHandler) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
      method: "POST",
      headers: createHeaders(), // Use the headers function
      body: JSON.stringify(credentials),
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON
    if (data && data.user) {
      if (successHandler) {
        successHandler(data);
        localStorage.setItem(
          "@antridge-organizaion/user",
          JSON.stringify(data.user)
        );
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};

export const changePassword = async (
  credentials,
  successHandler,
  errorHandler
) => {
  try {
    const response = await fetch(`${API_BASE_URL}/api/auth/change_password`, {
      method: "POST",
      headers: createHeaders(), // Use the headers function
      body: JSON.stringify({
        email: credentials.email,
        old_password: credentials.oldPassword,
        new_password: credentials.newPassword,
      }),
    });

    await handleErrors(response, errorHandler);
    const data = await parseJSON(response);

    if (data.message === "Password changed successfully") {
      successHandler?.(data.message);
    } else {
      errorHandler?.(data.message || "An error occurred");
    }
    return data;
  } catch (error) {
    errorHandler?.(error.message || "An error occurred");
    throw error;
  }
};
// Function to perform a POST request for login
export const fetchOrganization = async (
  orgId,
  successHandler,
  errorHandler
) => {
  try {
    let dataFromCache = retriveFromCache(orgId);
    if (dataFromCache) {
      return dataFromCache;
    }
    const response = await fetch(`${API_BASE_URL}/api/organization/${orgId}`, {
      method: "GET",
      headers: createHeaders(), // Use the headers function
    });

    // Apply middleware functions
    await handleErrors(response, errorHandler); // Check for HTTP errors
    const data = await parseJSON(response); // Parse JSON

    if (data && data.organization) {
      if (successHandler) {
        successHandler(data);
        storeInCache(orgId, data);
        // localStorage.setItem("@antridge-organizaion/user", JSON.stringify(data.user));
      }
    }
    return data;
  } catch (error) {
    // Handle or log errors if needed
    throw error;
  }
};
