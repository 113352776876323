import React, { useState, useEffect } from "react";
import {
	FormGroup,
	Input,
	Label,
	Button,
	Form,
	Row,
	Col,
	Container,
} from "reactstrap";
import {  Link, useNavigate } from "react-router-dom";
import { LoginCarousel } from "./carousel";
import AuthFooter from "./auth-footer";
// import { signin } from "../../redux/services/auth-service";

export const AuthLogin = ({user,loginUser}) => {
	
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [feedback, setFeedback] = useState("");
	const navigate = useNavigate()

	const onSignIn = async (e) => {
		e.preventDefault();
		
		if (email != "" && password != "") {
			await loginUser(
				{ email, password },
				() => window.location.reload(),
				errorHandler
			);
		} else {
			errorHandler("Please enter a valid email and password combination");
		}
	};

	const errorHandler = (msg) => {
		setErrorMessage(msg);
		setError(true);
	};

	useEffect(() => {
		if (error) {
			setFeedback(
				<div className="invalid-feedback d-block mb-2">{errorMessage}</div>
			);
		} else setFeedback("");
	}, [error]);

	useEffect(() => {
		setError(false);
		setErrorMessage("");
	}, [email, password]);

	return (
		<Row className="min-h-100 no-gutters ">
			<Col sm={12} md={8} lg={6} className="m-auto align-self-center py-4">
				<Row  className="px-3">
					<Col sm={12} md={6} className="d-flex">
						<img
              width="150"
              style={{objectFit:"contain"}}
							src={require("../../assets/images/logo/Antridge_Logo_PNG.png")}
						/>
						<p className="align-self-baseline">org</p>
					</Col>
				</Row>
				<div className="p-3 mt-3">
					<h4>Sign In</h4>
					<p>Access your proxima storage using your email and password.</p>
					{error&&<div className="invalid-feedback d-block mb-2">{errorMessage}</div>}
					<FormGroup>
						<Label for="exampleEmail">
							<b>Email</b>
						</Label>
						<Input
							type="email"
							name="email"
							id="exampleEmail"
							placeholder="Enter your email address"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</FormGroup>
					<FormGroup>
						<Label
							for="exampleEmail"
							className="d-flex justify-content-between"
						>
							<b>Password</b>
							<Link
								class="link link-primary link-sm"
								tabindex="-1"
								to={"/auth/reset-password"}
							>
								Forgot Password?
							</Link>
						</Label>
						<Input
							type="password"
							name="password"
							id="examplePassword"
							placeholder="Enter your password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</FormGroup>
					<Button
						size="md"
						color="primary"
						type="buttom"
						className="w-100 text-center mt-2"
						onClick={onSignIn}
					>
						Sign In
					</Button>
				</div>

				<Row className="p-3 mt-1">
					<Col
						sm={12}
						className="d-flex flex-direction-row p-3 mt-2 justify-content-center align-items-baseline"
					>
						<p className="h-60">I don't have an account? </p>{" "}
						<button
							size="md"
							color="primary"
							className=" text-center btn btn-link"
							onClick={() =>{}}
						>
							Get in touch
						</button>
					</Col>
				</Row>

				<AuthFooter />
			</Col>
		</Row>
	);
};
