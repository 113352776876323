export default class Eraser {
  constructor(radius) {
    this.radius = radius;
    this.currentPosition = null;
  }

  setPosition(x, y) {
    this.currentPosition = { x, y };
  }

  erase(shapes) {
    if (!this.currentPosition) return shapes;

    return shapes
      .flatMap((shape) => {
        if (
          shape.constructor.name === "Highlighter" ||
          shape.constructor.name === "Pencil" ||
          shape.constructor.name === "Pixelate"
        ) {
          const newShapes = [];
          let currentPoints = [];

          for (let i = 0; i < shape.points.length; i++) {
            const point = shape.points[i];
            if (this.distance(point, this.currentPosition) > this.radius) {
              currentPoints.push(point);
            } else {
              if (currentPoints.length >= 2) {
                const NewShape = shape.constructor;
                const newShape = new NewShape(
                  currentPoints[0].x,
                  currentPoints[0].y,
                  shape.color,
                  shape.width /
                    (shape.constructor.name === "Highlighter" ? 3 : 2)
                );
                newShape.points = [...currentPoints];
                if (shape.constructor.name === "Pixelate") {
                  newShape.pixelSize = shape.pixelSize;
                  newShape.strokeWidth = shape.strokeWidth;
                }
                newShapes.push(newShape);
              }
              currentPoints = [];
            }
          }

          if (currentPoints.length >= 2) {
            const NewShape = shape.constructor;
            const newShape = new NewShape(
              currentPoints[0].x,
              currentPoints[0].y,
              shape.color,
              shape.width / (shape.constructor.name === "Highlighter" ? 3 : 2)
            );
            newShape.points = [...currentPoints];
            if (shape.constructor.name === "Pixelate") {
              newShape.pixelSize = shape.pixelSize;
              newShape.strokeWidth = shape.strokeWidth;
            }
            newShapes.push(newShape);
          }

          return newShapes.length > 0 ? newShapes : null;
        }
        return shape;
      })
      .filter((shape) => shape !== null);
  }

  distance(point1, point2) {
    return Math.sqrt(
      Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2)
    );
  }

  draw(ctx) {
    if (!this.currentPosition) return;

    ctx.save();
    ctx.beginPath();
    ctx.arc(
      this.currentPosition.x,
      this.currentPosition.y,
      this.radius,
      0,
      Math.PI * 2
    );
    ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
    ctx.fill();
    ctx.strokeStyle = "#007bff";
    ctx.lineWidth = 2 / ctx.getTransform().a;
    ctx.stroke();
    ctx.restore();
  }
}
